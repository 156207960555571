// www.mobilsem.com  - Copyright .src/components/tourroom/TourroomForm.js
import React, { Component } from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FormField from '../FormField';
import ShowIcon from '../icons/ShowIcon';
import IconLoading from '../icons/IconLoading';
import { fieldsValidator } from '../../utils/helper';
import { appSubmitStart, appSubmitStop } from '../../actions/appActions';
import {
  getRoomInfo,
  getOptions,
  getObjFromListById,
  processValues,
} from '../../utils/commonutils';

const crudCode = {};

const tableCrud = 'tourroom';
const nameForm = 'tourroomForm';
const validate = (values, props) =>
  fieldsValidator(tableCrud, values, props, nameForm);

let log = false;
class Form extends Component {
  constructor(props) {
    super(props);
    this.updateBookings(props, 'constructor');
    setTimeout(
      function () {
        this.executeCode('onChangeInput', {
          action: 'initForm',
          props: this.props,
          formProps: this.props,
          formState: this.state,
        });
      }.bind(this),
      1000
    );
  }

  componentDidUpdate(prevProps) {
    // %% join with the constructor code, in a unique function
    // problem with constructor is executed just one time
    //console.log('this.props.containerPropsForm.crud_view_tourroom.tourroom;', this.props.containerPropsForm.crud_view_tourroom.tourroom);
    if (
      prevProps.containerPropsForm.crud_view_tourroom.tourroom !==
      this.props.containerPropsForm.crud_view_tourroom.tourroom
    ) {
      this.updateBookings(this.props, 'didupdate');
    }
  }

  updateBookings = (props, source) => {
    let tourroom = this.props.containerPropsForm.crud_view_tourroom.tourroom;
    //console.log('tourroom ', tourroom);
    let roomsqty = tourroom.room_quantity;
    let bookings = tourroom.bookings ? JSON.parse(tourroom.bookings) : {};
    let room_numbers = tourroom.room_numbers
      ? JSON.parse(tourroom.room_numbers)
      : {};
    //console.log('init bookings',bookings);
    let roomInfo = getRoomInfo(tourroom.room_type);
    //console.log('roomInfo ', roomInfo );

    let aBookings = {};
    let totalsRoom = {
      roomsbooked: 0,
      beds: 0,
      peoplecapacity: 0,
      peoplebooked: 0,
      peoplefreeplaces: 0,
    };

    for (let r = 1; r <= roomsqty; r++) {
      let beds = [];
      for (let d = 1; d <= roomInfo.bedQtyCouple; d++) {
        beds.push({ icon: 'doublebed' });
        totalsRoom.beds += 1;
      }
      for (let s = 1; s <= roomInfo.bedQtySingle; s++) {
        beds.push({ icon: 'singlebed' });
        totalsRoom.beds += 1;
      }

      totalsRoom.peoplecapacity += roomInfo.peoplePerRoom;
      // need same order than initvalues to prevent falsy alert before leave page TWIN BD212
      let roomnumber = room_numbers && room_numbers[r] ? room_numbers[r] : r;
      aBookings['room' + r] = { beds: [], roomnumber };

      let roomPeopleBooked = 0;
      for (let p = 0; p < beds.length; p++) {
        let bed = beds[p];
        // need same order than initvalues to prevent falsy alert before leave page TWIN BD212
        aBookings['room' + r].beds.push({
          bednumber: p,
          customers: [],
          id: r * 1000 + p,
          type: bed.icon,
        });
        //console.log('bookings room' +r ,bookings['room'+r] );
        if (
          bookings['room' + r] &&
          bookings['room' + r].beds[p] &&
          bookings['room' + r].beds[p].customers
        ) {
          //console.log('init  aBookings room'+r+'.beds.customers', aBookings['room'+r].beds[p].customers);
          aBookings['room' + r].beds[p].customers = [
            ...bookings['room' + r].beds[p].customers,
          ];
          roomPeopleBooked += aBookings['room' + r].beds[p].customers.length;
        }
      }
      totalsRoom.peoplebooked += roomPeopleBooked;
      if (totalsRoom.peoplebooked > roomInfo.peoplePerRoom)
        totalsRoom.roomsbooked++;
    }
    totalsRoom.peoplefreeplaces =
      totalsRoom.peoplecapacity - totalsRoom.peoplebooked;
    //console.log('totalpeoplePerRoom ',totalsRoom );

    if (source === 'didupdate') {
      if (this.state.aBookings !== aBookings) {
        this.setState({
          aBookings: aBookings,
          totalsRoom: totalsRoom,
        });
      }
    }
    if (source === 'constructor') {
      this.state = {
        crudAction: props.crudAction,
        crudTable: tableCrud,
        id: props.id ? props.id : '',
        disabledFields: [],
        hiddenFields: [],
        value: 'a',
        bedid: 0,
        customerid: '',
        history: [],
        aBookings: aBookings,
        totalsRoom: totalsRoom,
      };
    }
  };

  changeBed = (bedid) => {
    if (log) console.log('bedid', bedid);
    this.setState((state) => ({
      bedid: bedid,
    }));
  };

  handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (log) console.log('event', value);
    this.setState((state) => ({
      bedid: value,
    }));
  };

  removeCustomerBooked = (customer_id, t) => {
    if (log) console.log('cccccccccc', customer_id);
    let aBookings = JSON.parse(JSON.stringify(this.state.aBookings));
    let indexBedToDelete = null;
    let indexCustomerToDelete = null;
    let indexRoomToDelete = null;
    let totalsRoom = this.state.totalsRoom;
    Object.keys(aBookings).forEach((key) => {
      let aBooking = aBookings[key];
      aBooking.beds.map((bed, index) => {
        bed.customers.map((customer, indexcust) => {
          if (customer.id === customer_id) {
            indexRoomToDelete = key;
            indexBedToDelete = index;
            indexCustomerToDelete = indexcust;
            if (log)
              console.log(
                indexRoomToDelete,
                indexBedToDelete,
                indexCustomerToDelete
              );
          }
        });
      });
    });
    if (indexRoomToDelete) {
      totalsRoom.peoplebooked--;
      totalsRoom.peoplefreeplaces++;

      let newCustomers = [];
      let oldCustomers = [
        ...aBookings[indexRoomToDelete].beds[indexBedToDelete].customers,
      ];
      oldCustomers.map((customer) => {
        if (log)
          console.log('cccus compare:' + customer.id + ' with ' + customer_id);
        if (customer && customer.id !== customer_id) {
          newCustomers.push(customer);
        }
      });
      aBookings[indexRoomToDelete].beds[indexBedToDelete].customers =
        newCustomers;
    }
    if (log) console.log('nexobbbb(,aBookings', aBookings);
    this.setState(
      (state) => ({
        aBookings: aBookings,
        totalsRoom: totalsRoom,
        bedid: 0, // better reset bed selected, get confused if current room is another from delete
      }),
      () => {
        // in callback when state is really updated
        // need to change the main field that change to make work validation exit without save
        this.props.change('bookings', this.state.aBookings);
      }
    );
  };

  /*
  test direct mutate from form to apollo
  saveRoomsBooking = () => {
    console.log(' this.props.containerPropsForm', this.props.containerPropsForm);
    this.props.containerPropsForm.crud_update_tourroom(
      {
        variables:
          {
            id: this.props.id,
            bookings: JSON.stringify(this.state.aBookings),
            tour_id: this.props.containerPropsForm.crud_view_tourroom.tourroom,
          },
      },
    )
    .then(() => {
      alert('ok');
    });
  };*/

  handleSubmit = () => {
    if (log) console.log('this.props', this.props);
    this.props.appSubmitStart();
    // only these fiels are retrived to initialValues on form
    this.props.change('bookings', this.state.aBookings);
    this.props.change('people_capacity', this.state.totalsRoom.peoplecapacity);
    this.props.change('people_allocated', this.state.totalsRoom.peoplebooked);
    this.props.change(
      'people_available',
      this.state.totalsRoom.peoplefreeplaces
    );
    setTimeout(
      function () {
        this.props.handleSubmit();
      }.bind(this),
      1000
    );
  };

  handleChangeCustomer = (customer_id, t) => {
    let aBookings = this.state.aBookings;

    let customerObj = getObjFromListById(
      this.props.containerPropsForm.getTourRoomCustomers.getTourRoomCustomers,
      { customer_id }
    );

    let newBedId = this.state.bedid;
    let totalsRoom = this.state.totalsRoom;

    if (!this.state.bedid) {
      alert(t('info.selectBedToAssign'));
    } else {
      let roomNumber = Math.round(this.state.bedid / 1000);
      let bedNumber = this.state.bedid - roomNumber * 1000;
      if (log) console.log('rooomNumber', roomNumber);
      if (log) console.log('bedNumber', bedNumber);
      if (log) console.log('customer', customerObj.name);

      aBookings['room' + roomNumber].beds.map((bed, index) => {
        if (log)
          console.log(
            ' if (bed.bednumber === bedNumber): ' +
              bed.bednumber +
              '===' +
              bedNumber
          );
        let maxPeopleInBed = bed.type === 'singlebed' ? 1 : 2;
        if (bed.bednumber === bedNumber) {
          if (bed.customers.length < maxPeopleInBed) {
            if (bed.customers.length + 1 === maxPeopleInBed) {
              //console.log('bbbbbbbbbbbbed',bed.id++);
              //console.log('bedNumber >= aBookings[ room'+roomNumber+'].beds.length ',bedNumber,aBookings['room'+roomNumber].beds.length );
              if (bedNumber + 1 >= aBookings['room' + roomNumber].beds.length) {
                // bedNumber start with 0 (but is first bedà
                newBedId = (roomNumber + 1) * 1000;
              } else {
                newBedId++;
              }
            }
            aBookings['room' + roomNumber].beds[index].customers.push({
              id: customer_id,
              name: customerObj.name,
            });
            totalsRoom.peoplebooked++;
            totalsRoom.peoplefreeplaces--;
          }
        }
      });
      this.setState(
        (state) => ({
          aBookings: aBookings,
          bedid: newBedId,
          totalsRoom: totalsRoom,
        }),
        () => {
          // in callback when state is really updated
          // need to change the main field that change to make work validation exit without save
          this.props.change('bookings', this.state.aBookings);
        }
      );
    }
  };

  executeCode = async (methodCode = '', params = {}) => {
    //console.log('executeCode() methodCode:'+ methodCode + ', params:', params);
    if (!crudCode[methodCode]) {
      //do Nothing, silent, because can happens it's normal, crud have no definitions to actions , like onDisable,etc
      //alert ('error crudCode: '+methodCode+' not defined');
      return;
    }
    // important to getInputValue() or anothers where actions get complicated to pass values like this
    params.tableCrud = tableCrud;
    const result = await crudCode[methodCode](params);
    if (result && result.newStates) {
      // update states

      //console.log ('result.newStates',result.newStates);
      Object.keys(result.newStates).map((keyName, keyIndex) => {
        //console.log('set state: '+keyName+' ,value: ',result.newStates[keyName]);
        this.setState({ [keyName]: result.newStates[keyName] });
      });
    }
    // check not for true, but undefined, false is a valid value to return
    if (result && typeof result.valueToReturn !== 'undefined') {
      // update states

      return result.valueToReturn;
    }
  };

  render() {
    let tourroom = this.props.containerPropsForm.crud_view_tourroom.tourroom;

    let roomsqty = tourroom.room_quantity;

    if (log) console.log('tourroom', tourroom);
    let roomInfo = getRoomInfo(tourroom.room_type);

    const { value, history } = this.state;
    //console.log('this.props.containerPropsForm', this.props.containerPropsForm);
    if (log) console.log('this.state.aBookings', this.state.aBookings);
    const {
      error,
      handleSubmit,
      invalid,
      pristine,
      change,
      submitting,
      t,
      ...otherProps
    } = this.props;

    let Customers = [];

    /*
    resolve beds print by row, inside print aCustomers (list of persons)
    */

    //{ !bedFilled && <input style={ { fontSize: '23px' } } type="radio" name="roombed" value={bed.id} onChange={() => this.handleChange} />}

    let printBeds = {};
    let customersBooked = {};
    Object.keys(this.state.aBookings).forEach((key) => {
      let aBooking = this.state.aBookings[key];
      printBeds[aBooking.roomnumber] = [];

      aBooking.beds.map((bed, index) => {
        let maxPeopleInBed = bed.type === 'singlebed' ? 1 : 2;
        let aCustomers = [];
        // add customer to the room
        bed.customers.map((customer) => {
          let customerServicesJoined = [];
          let customerRoom = getObjFromListById(
            this.props.containerPropsForm.getTourRoomCustomers
              .getTourRoomCustomers,
            { customer_id: customer.id }
          );
          if (customerRoom) {
            //console.log('services', customerRoom);
            JSON.parse(customerRoom.services).map((service) => {
              customerServicesJoined.push(
                <div
                  key={service.id}
                  style={{
                    fontSize: '12px',
                    color: '#999999',
                    flexBasis: '210px',
                  }}
                >
                  *{service.name}
                </div>
              );
            });
          }
          aCustomers.push(
            <div key={'cnt' + customer.id}>
              <div
                key={customer.id}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <a
                  key="delete1"
                  onClick={() => this.removeCustomerBooked(customer.id)}
                >
                  <ShowIcon size="15" icon="close" color="darkred" />
                  {customer.name}
                </a>
              </div>
              <div>{customerServicesJoined}</div>
            </div>
          );

          customersBooked[customer.id] = {
            roomnumber: aBooking.roomnumber,
            bednumber: index + 1,
          };
        });
        // add points for places empty
        for (let c = aCustomers.length + 1; c <= maxPeopleInBed; c++) {
          aCustomers.push(
            <div key={'pp' + c} style={{ color: '#BBBBBB' }}>
              ..................................
            </div>
          );
        }
        //console.log('bed.id',bed.id);
        let bedFilled = bed.customers.length >= maxPeopleInBed;
        // for log bedid behaviour include inside the block: {this.state.bedid}/{bed.id}
        //border: 'solid 1px #cccccc',
        //minHeight: '135px',
        printBeds[aBooking.roomnumber].push(
          <div
            key={bed.id}
            style={{
              textAlign: 'center',
              color: '#555555',
              flexBasis: '250px',
              justifyContent: 'center',
              fontSize: '15px',
              padding: '5px',
            }}
          >
            <div style={{ fontSize: '16px', color: '#BBBBBB' }}>
              {t('form.bed')} {index + 1}
            </div>

            <a key="select1" onClick={() => this.changeBed(bed.id)}>
              <ShowIcon
                size="60"
                icon={bed.type}
                color={
                  bedFilled
                    ? '#6fb9de'
                    : this.state.bedid == bed.id
                    ? 'orange'
                    : '#cccccc'
                }
              />
            </a>

            {aCustomers}
          </div>
        );
      });

      //printBeds.push(</div></div>);
    });
    /*
    resolve rooms print
    */
    //'marginTop':'30px',
    let printBooking = [];
    Object.keys(this.state.aBookings).forEach((key) => {
      let aBooking = this.state.aBookings[key];
      printBooking.push(
        <div
          key={key}
          style={{
            display: 'flex',
            flexBasis: '200px',
            flexDirection: 'column',
            border: 'solid 1px #CCCCCC',
          }}
        >
          <div style={{ fontSize: '19px', padding: '3px', color: '#999999' }}>
            {t('table.tourroom')}{' '}
            <span style={{ color: '#6fb9de' }}>{aBooking.roomnumber}</span>
          </div>
          <div key={key} style={{ display: 'flex', marginTop: '4px' }}>
            <div
              style={{
                display: 'flex',
                marginTop: '10px',
                flexWrap: 'wrap',
                flexGrow: 1,
              }}
              onChange={this.handleChange}
            >
              {printBeds[aBooking.roomnumber]}
            </div>
          </div>
        </div>
      );
    });

    /*
     lateral right , list of customers
    */
    //if (!this.props.containerPropsForm.getTourRoomCustomers.loading  ) {

    this.props.containerPropsForm.getTourRoomCustomers.getTourRoomCustomers.map(
      (roomcustomer) => {
        //console.log(roomcustomer.services);
        Customers.push(
          <div
            key={roomcustomer.customer_id}
            style={{ marginTop: '2px  0px', minHeight: '40px' }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!customersBooked[roomcustomer.customer_id] && (
                <div>
                  <a
                    key="delete1"
                    onClick={() =>
                      this.handleChangeCustomer(roomcustomer.customer_id, t)
                    }
                  >
                    <ShowIcon size="25" color="orange" icon="navleft" />
                  </a>
                </div>
              )}
              {customersBooked[roomcustomer.customer_id] && (
                <div>
                  <a
                    key="delete1"
                    onClick={() =>
                      this.removeCustomerBooked(roomcustomer.customer_id, t)
                    }
                  >
                    <ShowIcon size="20" icon="close" color="#BBBBBB" />
                  </a>
                </div>
              )}

              <div style={{ fontSize: '15px', flexBasis: '210px' }}>
                {roomcustomer.name}
                {customersBooked[roomcustomer.customer_id] && (
                  <span style={{ color: '#6fb9de', fontSize: '17px' }}>
                    {' '}
                    ({customersBooked[roomcustomer.customer_id].roomnumber})
                  </span>
                )}
              </div>
              <div>
                <a
                  className="aToolBarExtend"
                  onClick={() =>
                    this.props.changePage(
                      `/registration-view/${roomcustomer.registration_id}`
                    )
                  }
                >
                  <ShowIcon size="25" icon="registration" color="gray" />
                </a>
              </div>
            </div>
            {(roomcustomer.room_shared ||
              roomcustomer.regonline_room_shared) && (
              <div
                style={{
                  marginLeft: '20px',
                  color: '#999999',
                  fontSize: '12px',
                }}
              >
                <div style={{ color: '#555555', fontSize: '13px' }}>
                  {t('form.room_shared_intended')}:
                </div>
                {roomcustomer.room_shared && (
                  <div>{roomcustomer.room_shared}</div>
                )}
                {roomcustomer.regonline_room_shared && (
                  <div style={{ color: '#67a03d' }}>
                    {roomcustomer.regonline_room_shared}
                  </div>
                )}
              </div>
            )}
            {JSON.parse(roomcustomer.services).map((service) => {
              return (
                <div
                  key={service.id}
                  style={{
                    fontSize: '12px',
                    color: '#999999',
                    flexBasis: '210px',
                  }}
                >
                  *{service.name}
                </div>
              );
            })}
          </div>
        );
      }
    );
    //}

    //<div style = {{ marginLeft: '25px' }}>{ customersBooked[roomcustomer.customer_id] && <span style={ { color:'#999999', fontSize: '15px'} }>{t('table.tourroom')}  {customersBooked[roomcustomer.customer_id].roomnumber}</span> }</div>
    //console.log(' state',this.state);

    // {t('form.filled')}: {this.state.totalsRoom.roomsbooked}
    //             <div>{t('form.roomTotalBeds')}: {this.state.totalsRoom.beds}</div>
    //         <div>{t('form.roompeoplePerRoom')}: {this.state.totalsRoom.peoplePerRoom}</div>
    let subToolBar = [];
    if (!this.props.myState || !this.props.myState.app.submitting) {
      subToolBar.push(
        <a key="save" onClick={() => this.handleSubmit()}>
          <ShowIcon size="37" color="#5995c3" icon="saveblack" />
        </a>
      );
    }

    if (this.props.myState && this.props.myState.app.submitting) {
      /* a wrapper to get same size button save */
      subToolBar.push(
        <a key="loading">
          <IconLoading size="35" />
        </a>
      );
    }

    return (
      <form onSubmit={handleSubmit}>
        <Field
          inputName="bookings"
          name="bookings"
          formProps={this.props}
          pathInTables="tourroom.fields.bookings"
          formState={this.state}
          nameForm="tourroomForm"
          component={FormField}
          executeCode={this.executeCode}
          type="hidden"
          typeInput="hidden"
          tableCrud={tableCrud}
        />

        <Field
          inputName="people_capacity"
          name="people_capacity"
          formProps={this.props}
          pathInTables="tourroom.fields.people_capacity"
          formState={this.state}
          nameForm="tourroomForm"
          component={FormField}
          executeCode={this.executeCode}
          type="hidden"
          typeInput="hidden"
          disabled={true}
        />
        <Field
          inputName="people_allocated"
          name="people_allocated"
          formProps={this.props}
          pathInTables="tourroom.fields.people_allocated"
          formState={this.state}
          nameForm="tourroomForm"
          component={FormField}
          executeCode={this.executeCode}
          type="hidden"
          typeInput="hidden"
          disabled={true}
        />
        <Field
          inputName="people_available"
          name="people_available"
          formProps={this.props}
          pathInTables="tourroom.fields.people_available"
          formState={this.state}
          nameForm="tourroomForm"
          component={FormField}
          executeCode={this.executeCode}
          type="hidden"
          typeInput="hidden"
          disabled={true}
        />

        <div
          style={{
            border: 'solid 1px #999999',
            maxWidth: '700px',
            padding: '10px',
          }}
        >
          <div style={{ fontSize: '19px', color: '#457ca1' }}>
            {roomsqty} {t('table.tourrooms')}, {t('form.type')}:{' '}
            {roomInfo.roombase_type.name}{' '}
            {tourroom.name && <span> *{tourroom.name}</span>}
          </div>
          <div
            style={{
              fontSize: '17px',
              color: '#777777',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div style={{ flexGrow: '1' }}>
              {t('form.people_capacity')}:{' '}
              {this.state.totalsRoom.peoplecapacity}
            </div>
            <div style={{ flexGrow: '1' }}>
              {t('form.people_allocated')}: {this.state.totalsRoom.peoplebooked}
            </div>
            <div style={{ flexGrow: '1' }}>
              {t('form.people_available')}:{' '}
              {this.state.totalsRoom.peoplefreeplaces}
            </div>
          </div>
        </div>

        <div className="appBodyTitleSeparator" />
        <div style={{ color: '#AAAAAA' }}>{t('info.clickbedtobook')}</div>

        <div key="save01">{subToolBar}</div>

        {/* Very nice effect with flex, when it's possible and list of customer (right) is filled and more height
        than normal rooms (left),  the left columns (rooms) take same heithg of right, taking extra bottom padding spaces
        that'is nice because a lot of customer on right , don't let small height on the left, so it's visible
        rooms & bed in the same height
        */}
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {printBooking}
          </div>
          <div style={{ minWidth: '250px', marginRight: '7px' }}>
            <div style={{ fontSize: '19px', color: '#777777' }}>
              {t('table.customers')}
            </div>
            {Customers}
          </div>
        </div>
        <div key="save02">{subToolBar}</div>

        <div>
          {error && <strong>{error}</strong>}

          <div className="formError">
            {pristine}
            {invalid && t('form.haserrors')}
          </div>
        </div>
      </form>
    );
  }
}
//flexGrow: roomInfo.bedsPerRoom < 4 ? roomInfo.bedsPerRoom : 3
//fontSize: '19px', flexGrow: 1,  'marginTop':'30px'

const ComponentWithData = reduxForm({
  form: nameForm, // a unique identifier for this form
  enableReinitialize: true,
  touchOnChange: true, // important for this case, manual .change must touche to be controlled by exit wihtout save logic
  validate,
})(Form);

function mapStateToProps(state, ownProps) {
  // to view data , need to pass the values to redux-form trough initialValues
  // from graphl data , not important to 'add' action, there no values

  const statesReturn = { myState: state };
  if (ownProps.id) {
    // not for add component, just view
    const values = processValues(
      ownProps,
      tableCrud,
      ownProps.data,
      'toClient',
      'view'
    );
    //console.log('values', values);
    statesReturn.initialValues = values;
    /*
    %%% tried just initializee only for field will update this form
    but get warning change field after save, study to retrieve initivalvalues again or manually
       i think turn off enableReinitialize: true,  and get new values by hand
    statesReturn.initialValues = {
       id: values.id,
       bookings: values.booking,
       people_capacity: values.people_capacity,
       people_allocated: values.people_available,
    };*/
  }
  return statesReturn;
}

const ComponentWithDataAndState = connect(mapStateToProps, {
  appSubmitStart,
  appSubmitStop,
})(ComponentWithData);

export default ComponentWithDataAndState;
