import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import PageHeader from '../PageHeader';
import { resolvePathObj } from '../../utils/commonutils';
class MyAccount extends Component {
  render() {
    const channelSMS = resolvePathObj(
      this.props,
      'myState.app.appSettings.modules.SMS',
      { notFound: null }
    );

    const { t } = this.props;
    return (
      <div>
        <PageHeader
          title="menu.mailing"
          icon="mailing"
          key="pageheader"
          t={t}
          helpLink="https://docs.google.com/document/d/19CehNeSk2ObMxQ-jr4y1faR3K-0-wi2fgjQBcVKljm4/edit#heading=h.ot370tiexjki"
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Link style={{ margin: '10px' }} to="/ejobmail">
            ► {t('table.ejobmails')}
          </Link>
          <Link style={{ margin: '10px' }} to="/ejobday">
            ► {t('table.ejobdays')}
          </Link>
          <Link style={{ margin: '10px' }} to="/ejobmonth">
            ► {t('table.ejobmonths')}
          </Link>
          <Link
            style={{ margin: '10px' }}
            to="/etemplate?type=TXT"
            className="navbar"
          >
            ► {t('table.etemplates')} {t('table.registrations')}
          </Link>
          {channelSMS && (
            <Link
              style={{ margin: '10px' }}
              to="/etemplate?type=CUS"
              className="navbar"
            >
              ► {t('table.etemplates')} {t('table.customers')}
            </Link>
          )}

          {channelSMS && (
            <Link
              style={{ margin: '10px' }}
              to="/etemplate?type=SMS"
              className="navbar"
            >
              ► {t('table.etemplates')} SMS
            </Link>
          )}
          <Link style={{ margin: '10px' }} to="/esettings" className="navbar">
            ► {t('menu.settings')}
          </Link>
        </div>
      </div>
    );
  }
}
export default MyAccount;
