const strArgs = {};
strArgs['customeraddress'] = `($id: ID, $_qlType: String, $_filter: String) {
          customeraddresss  (id: $id, _qlType: $_qlType, _filter: $_filter)`;
strArgs[
  'person'
] = `($lastname: String, $firstname: String, $addresses_city_id: ID, $inactive: Boolean, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          persons  (lastname: $lastname, firstname: $firstname, addresses_city_id: $addresses_city_id, inactive: $inactive, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs[
  'customer'
] = `($numbering: String, $lastname: String, $firstname: String, $email: String, $customerstatus_id: String, $addresses_city_id: ID, $inactive: Boolean, $nosharedata: Boolean, $nonewsletter: Boolean, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          customers  (numbering: $numbering, lastname: $lastname, firstname: $firstname, email: $email, customerstatus_id: $customerstatus_id, addresses_city_id: $addresses_city_id, inactive: $inactive, nosharedata: $nosharedata, nonewsletter: $nonewsletter, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs[
  'tour'
] = `($name: String, $_multidate: String, $_multidate1: String, $_multidate2: String, $_multidateField: String, $inactive: Boolean, $id: ID, $_qlType: String, $_filter: String) {
          tours  (name: $name, _multidate: $_multidate, _multidate1: $_multidate1, _multidate2: $_multidate2, _multidateField: $_multidateField, inactive: $inactive, id: $id, _qlType: $_qlType, _filter: $_filter)`;
strArgs[
  'tourcategory'
] = `($id: ID, $name: String, $_qlType: String, $_filter: String) {
          tourcategorys  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs[
  'transportation'
] = `($tour_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          transportations  (tour_id: $tour_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs[
  'tourprice'
] = `($tour_id: String, $id: ID, $_qlType: String, $_filter: String) {
          tourprices  (tour_id: $tour_id, id: $id, _qlType: $_qlType, _filter: $_filter)`;
strArgs[
  'tourroom'
] = `($tour_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          tourrooms  (tour_id: $tour_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs[
  'regonline'
] = `($tour_id: ID, $state: Int, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          regonlines  (tour_id: $tour_id, state: $state, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs[
  'registration'
] = `($numbering: String, $customer_id: ID, $tour_id: ID, $transportation_transportation_id: String, $tourroom_id: String, $registrationstatus_id_statusbase: Int, $customerstatus_id: String, $customersubstatus_id: String, $confirmationstatus_id_statusbase: Int, $_balance: String, $assignedroom: Boolean, $_channel: String, $_withmobile: String, $inactive: Boolean, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          registrations  (numbering: $numbering, customer_id: $customer_id, tour_id: $tour_id, transportation_transportation_id: $transportation_transportation_id, tourroom_id: $tourroom_id, registrationstatus_id_statusbase: $registrationstatus_id_statusbase, customerstatus_id: $customerstatus_id, customersubstatus_id: $customersubstatus_id, confirmationstatus_id_statusbase: $confirmationstatus_id_statusbase, _balance: $_balance, assignedroom: $assignedroom, _channel: $_channel, _withmobile: $_withmobile, inactive: $inactive, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs[
  'customerhistory'
] = `($customer_id: String, $id: ID, $_qlType: String, $_filter: String) {
          customerhistorys  (customer_id: $customer_id, id: $id, _qlType: $_qlType, _filter: $_filter)`;
strArgs[
  'paymenthistory'
] = `($payment_id: String, $id: ID, $_qlType: String, $_filter: String) {
          paymenthistorys  (payment_id: $payment_id, id: $id, _qlType: $_qlType, _filter: $_filter)`;
strArgs[
  'registrationhistory'
] = `($registration_id: String, $id: ID, $_qlType: String, $_filter: String) {
          registrationhistorys  (registration_id: $registration_id, id: $id, _qlType: $_qlType, _filter: $_filter)`;
strArgs[
  'payment'
] = `($check_number: String, $application_customer_id: String, $application_tour_id: String, $paymentdocstatus_id: String, $paymentmethod_id: String, $paymentmethod_id_ajournalcode_id: String, $_balanceinit: Float, $name: String, $amount: Float, $_depositedcheck: Int, $_multidate: String, $_multidate1: String, $_multidate2: String, $_multidateField: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          payments  (check_number: $check_number, application_customer_id: $application_customer_id, application_tour_id: $application_tour_id, paymentdocstatus_id: $paymentdocstatus_id, paymentmethod_id: $paymentmethod_id, paymentmethod_id_ajournalcode_id: $paymentmethod_id_ajournalcode_id, _balanceinit: $_balanceinit, name: $name, amount: $amount, _depositedcheck: $_depositedcheck, _multidate: $_multidate, _multidate1: $_multidate1, _multidate2: $_multidate2, _multidateField: $_multidateField, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs[
  'checkdeposithead'
] = `($name: String, $id: ID, $_qlType: String, $_filter: String) {
          checkdepositheads  (name: $name, id: $id, _qlType: $_qlType, _filter: $_filter)`;
strArgs[
  'checkdepositdet'
] = `($checkdeposithead_id: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          checkdepositdets  (checkdeposithead_id: $checkdeposithead_id, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs[
  'export'
] = `($id: ID, $name: String, $_qlType: String, $_filter: String) {
          exports  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs[
  'hotel'
] = `($id: ID, $name: String, $_qlType: String, $_filter: String) {
          hotels  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs[
  'stopspoint'
] = `($id: ID, $name: String, $_qlType: String, $_filter: String) {
          stopspoints  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs[
  'service'
] = `($name: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          services  (name: $name, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs[
  'city'
] = `($name: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          citys  (name: $name, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs[
  'registrationstatus'
] = `($id: ID, $name: String, $_qlType: String, $_filter: String) {
          registrationstatuss  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs[
  'confirmationstatus'
] = `($id: ID, $name: String, $_qlType: String, $_filter: String) {
          confirmationstatuss  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs[
  'bank'
] = `($id: ID, $name: String, $_qlType: String, $_filter: String) {
          banks  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs[
  'paymentmethod'
] = `($id: ID, $name: String, $_qlType: String, $_filter: String) {
          paymentmethods  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs[
  'paymentdocstatus'
] = `($id: ID, $name: String, $_qlType: String, $_filter: String) {
          paymentdocstatuss  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs[
  'customerstatus'
] = `($id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          customerstatuss  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs[
  'gendertype'
] = `($id: ID, $name: String, $_qlType: String, $_filter: String) {
          gendertypes  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs[
  'userlog'
] = `($user_id: String, $action: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          userlogs  (user_id: $user_id, action: $action, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs[
  'user'
] = `($nick: String, $longname: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          users  (nick: $nick, longname: $longname, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs[
  'usergroup'
] = `($id: ID, $name: String, $_qlType: String, $_filter: String) {
          usergroups  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs[
  'ejobmail'
] = `($title: String, $customer_id: String, $tour_id: ID, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          ejobmails  (title: $title, customer_id: $customer_id, tour_id: $tour_id, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs[
  'etemplate'
] = `($name: String, $title: String, $type: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          etemplates  (name: $name, title: $title, type: $type, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs[
  'ejobday'
] = `($id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          ejobdays  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs[
  'ejobmonth'
] = `($id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          ejobmonths  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs[
  'aplan'
] = `($name: String, $title: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          aplans  (name: $name, title: $title, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs[
  'acostplan'
] = `($name: String, $title: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          acostplans  (name: $name, title: $title, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs[
  'ajournalcode'
] = `($name: String, $title: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          ajournalcodes  (name: $name, title: $title, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;

export default strArgs;
