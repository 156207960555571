// www.mobilsem.com  - Copyright .src/components/settings/Settings.js
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router';
import { graphql, compose } from 'react-apollo';
import { bindActionCreators } from 'redux';

import PageHeader from '../PageHeader';
import defQls from '../../defQls';
import { processError } from '../../utils/helper';
import Form from './EsettingsForm';
import { gqlActions } from '../../gqls/gqlactions';
import {
  appSettingsLoad,
  appSubmitStart,
  appSubmitStop,
} from '../../actions/appActions';

let aFields = {
  //'esettings.email': {},
  //'esettings.smtpserver': {},
  //'esettings.smtpport': {},
  //'esettings.password': {},
  'esettings.header': {},
  'esettings.signature': {},
  'esettings.signaturesms': {},
  'esettings.badgetinfo': {},
  'esettings.labelstemplate': {},
  'esettings.templatenewpreregistration': {},

  //'esettings.verified': {},
};

class Settings extends Component {
  constructor(props) {
    super(props);
  }

  handleSubmit = async (values) => {
    //console.log('values',values);
    // need to reload organisator config immediately for forms impact (eg. fields tip in inscription)
    let newValues = {};
    // this Settings is stringified by every key before to save
    let newSettings = {};

    for (const [key, value] of Object.entries(aFields)) {
      const [groupKey, subkey] = key.split('.');
      if (!newValues[groupKey]) {
        newValues[groupKey] = {};
      }
      newValues[groupKey][subkey] = values[subkey];
    }

    for (const [key, value] of Object.entries(newValues)) {
      newSettings[key] = JSON.stringify({ ...value });
    }

    gqlActions('', 'setOrgSettings', this.props, newSettings)
      .then((response) => {
        //suspended ; dont load modules settings
        //this.props.appSettingsLoad(newValues);
      })
      .catch((err) => {
        processError(err, this.props, true);
      });
  };

  render() {
    const { error, pristine, invalid, reset, submitting, t } = this.props;

    let aElements = [];
    aElements.push(
      <PageHeader
        key="pageheader"
        icon="settings"
        title="menu.settings"
        t={t}
        helpLink="https://docs.google.com/document/d/19CehNeSk2ObMxQ-jr4y1faR3K-0-wi2fgjQBcVKljm4/edit#heading=h.rol8w9iam8yz"
      />
    );

    aElements.push(
      <Form
        key="form"
        onSubmit={this.handleSubmit}
        t={t}
        aFields={aFields}
        containerPropsForm={this.props}
      />
    );

    return <div>{aElements}</div>;
  }
}

const withRouterList = withRouter(Settings);
const withGraphqlandRouter = compose(
  graphql(defQls.setOrgSettings, {
    name: 'setOrgSettings',
    options: (props) => {
      const optionsValues = { variables: { id: props.match.params.id } };
      optionsValues.fetchPolicy = 'network-only';
      return optionsValues;
    },
  }),
  graphql(defQls.getOrgSettings, {
    name: 'getOrgSettings',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = 'network-only';
      let filters = {};

      optionsValues.variables = { ...filters };
      return optionsValues;
    },
  }),
  graphql(defQls.etemplate.ListMini, {
    name: 'list_etemplate',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = 'network-only';
      optionsValues.variables = { _qlType: 'ListMini' };
      return optionsValues;
    },
  })
)(withRouterList);

const mapStateToProps = (state, ownProps) => {
  return {
    myState: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { appSettingsLoad, appSubmitStart, appSubmitStop, dispatch },
    dispatch
  );
};

const ComponentFull = connect(
  mapStateToProps,
  mapDispatchToProps
)(withGraphqlandRouter);

export default ComponentFull;
