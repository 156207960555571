// test 2

import React, { Component, Fragment } from 'react';
import debounce from 'lodash/debounce';
import { translate } from 'react-i18next';
//import { enableUniqueIds } from 'react-html-id';
import Select, { Creatable } from 'react-select';
import SelectNext from 'react-select-next';
//import Autosuggest from 'react-autosuggest';

import MaskedInput from 'react-text-mask';
import ShowIcon from './icons/ShowIcon';
import { Async } from 'react-select';
import { fetchQl } from '../apolloClient';
import { realTypeOf } from '../utils/commonutils';

import { errorTranslate } from '../utils/helper';
import {
  sourcesOptions,
  getOptions,
  resolvePathObj,
  getObjFromListById,
  getInputValue,
  tt,
} from '../utils/commonutils';
import { Tables } from '../defTables';

// the value that will fill input, from option (need to specify the property of object option)
const getSuggestionValue = (suggestion) =>
  // console.log('getSuggestionValue',suggestion );
  suggestion.name;
const randId = () => {
  return Math.random().toString(36).substr(2, 10);
};

let setDateFormat = localStorage.getItem('setDateFormat');

const logOptionsFromState = false;

const createAutoCorrectedDatePipe = (dateFormat = 'dd mm yyyy') =>
  function (conformedValue) {
    const indexesOfPipedChars = [];
    const dateFormatArray = dateFormat.split(/[^dmyHMS]+/);
    const maxValue = {
      dd: 31,
      mm: 12,
      yy: 99,
      yyyy: 9999,
      HH: 24,
      MM: 59,
      SS: 59,
    };
    const minValue = { dd: 1, mm: 1, yy: 0, yyyy: 1, HH: 0, MM: 0, SS: 0 };
    const conformedValueArr = conformedValue.split('');

    // Check first digit
    dateFormatArray.forEach((format) => {
      const position = dateFormat.indexOf(format);
      const maxFirstDigit = parseInt(
        maxValue[format].toString().substr(0, 1),
        10
      );

      if (parseInt(conformedValueArr[position], 10) > maxFirstDigit) {
        conformedValueArr[position + 1] = conformedValueArr[position];
        conformedValueArr[position] = 0;
        indexesOfPipedChars.push(position);
      }
    });

    // Check for invalid date
    const isInvalid = dateFormatArray.some((format) => {
      const position = dateFormat.indexOf(format);
      const length = format.length;
      const textValue = conformedValue
        .substr(position, length)
        .replace(/\D/g, '');
      const value = parseInt(textValue, 10);

      return (
        value > maxValue[format] ||
        (textValue.length === length && value < minValue[format])
      );
    });

    if (isInvalid) {
      return false;
    }

    return {
      value: conformedValueArr.join(''),
      indexesOfPipedChars,
    };
  };

// Render with a template for option. accord to properties from object option
const renderSuggestion = (suggestion) => {
  // console.log('renderSuggestion',suggestion);
  let optionText = '';
  if (suggestion.name) {
    optionText = suggestion.name;
  }
  return <div>{optionText}</div>;
};

class FormField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocus: false,
      // for autosuggest input, need to initiliaze value accord to redux state,
      // becase assigning value directly on the property, redux state is not still existe, but here yes
      // just one time in construct
      // UPDATED: this.getSuggestValue() resolution, moved to inputProps in component autosugest,
      // it's the way to work with state: redux, and initialize value
      // value:  props.typeInput === 'freeAutocomplete'? this.getSuggestValue():'',
      value: '',
      suggestions: [],
      typeInput: props.typeInput,
      conditionalRequired: false,
      conditionalfilterBy: false,
    };

    ///enableUniqueIds(this);
  }

  componentDidMount() {
    /* movet to construct, to not use timeout
     if (this.state.typeInput === 'freeAutocomplete') {
       setTimeout(function() {
         this.setState({value: this.getSuggestValue() }); //
        }.bind(this), 500);
     } */
    // if (this.props.) {
    /*
    not needed because reprocess.js
    if (this.props.tableCrud === 'registration') {
       setTimeout(function() {
          this.controlChanged();
        }.bind(this), 1500);
    } */
  }

  setFocus = (hasFocus) => {
    this.setState({ isFocus: hasFocus });
  };

  onChangeSuggest = (event, { newValue }) => {
    this.props.input.onChange(newValue);
    this.setState({
      value: newValue,
    });
  };

  // Come from autocomplete, need:
  //    1. to transport to onchange of redux-form input
  //    2. need to update redux value with input.onChange(), it's manual
  onChangeAutocomplete = async (inputName, event) => {
    const nameForm = this.props.nameForm
      ? this.props.nameForm
      : `${this.props.tableCrud}Form`;
    //%% but the momente, don't support inputFullName in subfields ( look for how to add the parent field on the case)

    //not good to control changes, must be null  REFSERVER: AUTO829
    //let newValue ='';
    let newValue = null; // next block can use this default (this.props.executeCode), must be null
    if (typeof event !== 'undefined' && event !== null) newValue = event.id;
    /// don't use: this.props.formProps.executeCode (because every field has the right executeCode
    /// and for indepented form like MemberAddress.js executeCode() is customized and send manually
    await this.props.executeCode('onChangeInput', {
      nameForm,
      inputFullName: inputName,
      formProps: this.props.formProps,
      formState: this.props.formState,
      event,
      newValue,
    });

    setTimeout(() => {
      if (this.props.input.onChange) {
        // import parenthesis befor negation , == null, control if is null or undefined
        if (typeof this.props.input !== 'undefined' && !(event == null)) {
          // //console.log(typeof this.props);
          this.props.input.onChange({ id: event.id, name: event.name });
        } else {
          //not good to control changes, must be null  REFSERVER: AUTO829
          //this.props.input.onChange('');
          this.props.input.onChange(null);
        }
      }
    }, 100);
  };

  getSuggestValue = () => {
    const log = false;
    const nameForm = this.props.nameForm
      ? this.props.nameForm
      : `${this.props.tableCrud}Form`;

    /* Example for field with subfields:
       inputName = application[0].tour_id
       It must to extract the row,
     */
    let inputName = this.props.input.name;
    let inputNameArray;
    if (this.props.isSubfield) {
      inputNameArray = inputName.match(/(\w+)\[([\d*]+)\]\.(\w+)/);
      inputName = inputNameArray[1]; // real name
      if (log) {
        // console.log('getvalue() internal-----------,form:'+nameForm + ', input:'+inputName);
        // console.log('formValues:',this.props.myState.form[nameForm].values);
      }
    }

    if (log) {
      // console.log('getvalue() internal-----------,form:'+nameForm + ', input:'+inputName);
      // console.log('formValues:',this.props.myState.form[nameForm].values);
    }

    // console.log('getSuggestValue props',this.props);
    // this.props.formProps  for high securiry, normal must to exists, at least is calling from another type of input
    let valueState = getInputValue(this.props.formProps, inputName, {
      nameForm,
    });
    if (valueState) {
      let valueReal = valueState;
      if (log) console.log('valueReal :', valueReal);

      if (this.props.isSubfield && valueReal) {
        if (valueReal) {
          valueReal = valueReal[inputNameArray[2]][inputNameArray[3]];
          if (log) console.log('isSubfield, valueReal :', valueReal);
        }
      }
      /*
       Important need to set from redux value :
          ruta: state, form, formname, values, field name
       for the input name to Select autocomplete component
       using  the method getValue()
       para saber que campo tomar de redux, usa la propiedad del componente select "this.props.input.name"
        */
      // console.log('resolution value:', valueReal);

      return valueReal;
      // return {id:'050702d2-7113-4392-84fb-ad5769617ed5', name:'Lourdes 2018'};
    }
    return '';
  };
  /*
  Look for the value (one value) stored in redux for the field
  it's not list of values, just single value to load n the field
   */

  // Autosuggest will call this function every time you need to update suggestions.
  onSuggestionsFetchRequested = ({ value }) => {
    // load list of options from state
    // console.log('onSuggestionsFetchRequested, value:', value )
    // console.log('options:',this.props.options);
    this.setState({
      suggestions: this.loadOptionsForSuggestion(value), // this.props.options //
    });
  };

  loadOptionsForSuggestion = (currentInputValue) => {
    const log = false;
    if (log)
      console.log(
        'loadOptionsForSuggestion:: currentInputValue:',
        currentInputValue
      );

    const optionsResult = [];
    let inputValue;
    if (currentInputValue) {
      inputValue = currentInputValue.trim().toLowerCase();
    } else {
      inputValue = '';
    }
    const inputLength = inputValue.length;
    const optionsSource = this.state.options;

    if (log) console.log('optionsSource this.state:', this.state);
    for (let i = 0; i < optionsSource.length; i++) {
      const option = optionsSource[i];
      if (inputLength > 0) {
        if (option.name.toLowerCase().slice(0, inputLength) === inputValue) {
          optionsResult.push(option);
        }
      } else {
        optionsResult.push(option);
      }
      if (optionsResult.length > 15) break;
    }
    // results is small so complet optiosn with another options, that are not

    if (inputLength > 0 && optionsResult.length < 5) {
      for (let i = 0; i < optionsSource.length; i++) {
        const option = optionsSource[i];
        if (log) console.log('optionsResult <5, option:', option);
        if (option.name.toLowerCase().slice(0, inputLength) !== inputValue) {
          optionsResult.push(option);
        }
        if (optionsResult.length > 15) break;
      }
    }

    return optionsResult;
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  /*
   get the value  from state for autocomplete async , this elemente need always set value retrieved
   from state, is not enought like  another inputs, it's a fake selectbox so need to update value
   same for sync autocomplete , but use normal way to get value with getInputValue()
   async need { id, name} and sync only string:id
   */
  getAsyncInputValue = (tablename, inputName, valueCurrentInput) => {
    let log = false;

    let tableCrud = this.props.pathInTables.split('.');
    tableCrud = tableCrud[0];
    if (log)
      console.log(
        'this.props.pathInTables:' +
          this.props.pathInTables +
          ', tableCrud:' +
          tableCrud
      );
    if (log)
      console.log(
        'getAsyncInputValue() tablename:' +
          tablename +
          ', inputName:' +
          inputName +
          ',valueCurrentInput:' +
          valueCurrentInput
      );
    if (log) console.log('props', this.props);

    // %% implementar for subfields
    //  example: addresses[0].city_id:  inputName=  ,  => city in subfields: addresses.0.city_id
    let value;
    // in list crud there is no 'initialValues' , then need check this.props.formProps.initialValues
    let nameFieldLookFor = tableCrud + inputName; // old = tablename+inputName
    if (log)
      console.log(
        'this.props.formProps.initialValues: (will look for ' +
          nameFieldLookFor +
          ')',
        this.props.formProps.initialValues
      );
    // some fields need only id, but in next section if name is found then send an object
    // it's important to display the value because sync, don't charge nothing, but with value, is need an object value
    // to fill the selectbox
    value = getInputValue(this.props);

    if (log) console.log('getInputValue ' + inputName, value);

    if (log) console.log('value:', value);
    return value;
  };

  // new HOC with debouce, need to work with callback to works with react-select
  debouncedloadSyncOptions =
    (
      tablename,
      inputName,
      valueCurrentInput,
      options = { action: '', fieldDisplayedOptions: {}, sourceField: {} }
    ) =>
    (input, callback) => {
      if (!input) {
        return callback(null, { options: [] });
      }
      //console.log('try debounced tablename/input', tablename, input);
      const resDeb = this.debounced(
        tablename,
        inputName,
        valueCurrentInput,
        options,
        input,
        callback
      );
    };

  // HOC for async input
  // loadSyncOptions =  (tablename , inputName, valueCurrentInput, options = {action: '',  fieldDisplayedOptions: {}, sourceField: {} })=>  (input) => {
  // new with debounce: https://github.com/JedWatson/react-select/issues/2476
  loadSyncOptions = async (
    tablename,
    inputName,
    valueCurrentInput,
    options = { action: '', fieldDisplayedOptions: {}, sourceField: {} },
    input,
    callback
  ) => {
    let log = false;
    if (log)
      console.log(
        'loadSyncOptions() tablename:' +
          tablename +
          ', inputName:' +
          inputName +
          ',valueCurrentInput:' +
          valueCurrentInput +
          ', input:' +
          input
      );
    let variables;
    if (!input || input.trim().length === 0) {
      if (log) console.log('input value is blank');
      if (valueCurrentInput && !valueCurrentInput.id) {
        //always add the current value when there is not input typed, but only if detecte that
        //current value is not {id, name} so don't send value ( {id,name} ) inside id
        //case: city, seleted city after type, or on enter or when lost focus
        // is useful this logic? it was did when client did not get {id, name} from start ?
        // is a code to retrieve last value when input is white? ( it's semmes not, automcomplete restore automatica, when value typed is abandoned..)

        variables = [{ name: 'id', type: 'ID', value: valueCurrentInput }];
        if (log)
          console.log(
            'loadSyncOptions()  there is valueCurrentInput, fetch by id:',
            variables
          );
        return fetchQl(tablename, variables, input, {
          props: this.props.formProps,
        });
      } else if (valueCurrentInput && valueCurrentInput.id) {
        // this sections happens when the options is selected, after type , search and to choose
        // save lof
        //console.log('selected:',valueCurrentInput.id);
      } else {
        if (log) console.log('return array empty for options');
        return Promise.resolve({ options: [] });
      }
    }
    variables = [{ name: 'name', type: 'String', value: input }];
    // if table dont' use inactive, no problem; ql process will ignore it
    if (options.action === 'Add' && Tables[tablename].fields.inactive)
      variables.push({ name: 'inactive', type: 'Boolean', value: false });
    // every keypress launch this fetch
    if (log) console.log('loadSyncOptions()  go to fetch', variables);
    // return empty screen if there is loadMin, and length is minor
    if (
      options.sourceField &&
      options.sourceField.loadMin &&
      input &&
      input.length < options.sourceField.loadMin
    )
      return Promise.resolve([]);
    // looks works ok without await, because return Promise at the end
    // important parameter _qlType: 'ListMini' , the sql just will get some fields, and not all fields, normally is just name
    // for customers will be more ....
    const valuesFetch = await fetchQl(tablename, variables, {
      input,
      props: this.props.formProps,
      _qlType: 'ListMini',
    }); // third parameter still not used
    //console.log('debounced', variables);
    //const valuesFetch = this.debounced(tablename, variables, {input, props: this.props} ); // third parameter still not used
    //console.log('valuesFetch', valuesFetch);
    // did not arrived to convery to convert fetch in asycn, and resolve promise, and return promises
    if (false === true && valuesFetch && options.fieldDisplayedOptions) {
      valuesFetch.map((record) => {
        let nameRow = '';
        options.fieldDisplayedOptions.map((fieldName) => {
          //console.log('fieldName',fieldName);
          //console.log('field',field);
          /*
          look for subRelated table. Eg. registration has tourroom_id related (tourroom table),
          tourroom has hotel_id (hotel table) is subrelated table
           */
          let sqlfieldName;
          let fieldrow;
          // version simplified TWIN L2031
          sqlfieldName = fieldName;
          fieldrow = record[sqlfieldName];
          if (fieldrow) nameRow += (nameRow ? ', ' : '') + fieldrow;
        });
        ///return {...record, name: nameRow};
      });
    }
    //localStorage.setItem( tablename ,  JSON.stringify(valuesFetch) ); // set in cache localstore the result of search
    //return Promise.resolve (valuesFetch);
    //console.log('valuesFetch', valuesFetch); // valuesFetcuh = { options: [...]}
    //return valuesFetch;
    callback(null, valuesFetch); // with debounce dont work simple return promise, need use callbak
  };

  debounced = debounce(this.loadSyncOptions, 700);

  render() {
    let log = false;

    const styleReadOnly = {
      color: '#333333',
      display: 'flex',
      height: '37px',
      alignItems: 'center',
      minWidth: '300px',
    };

    /*
      don't send type (it's for type of field on the db), the right is typeInput, for the type in input
     */

    /*
     Warning !, options be presente   like property in <Select>, and not printed with ...myInput , for that reason
     tha variable options is here presented in destructuring, and not let it like a part of ...otherPros, because it' seemns
     not updated with the react logic ,need be written by hand, calling the props of his parent where the options for the
     select autocomplete is loaded:
     <Field
     component={FormField}
     type="selectAutocomplete"
     typeInput="selectAutocomplete"
     options={getOptions('tour',this.props)}
    */

    // console.log('form field props',this.props);
    const {
      t,
      icon,
      animatePath,
      options,
      input,
      meta: { touched, error, warning, submitFailed },
      ...otherProps
    } = this.props;

    if (log) {
      console.error('----new PROPS FORM FIELD: ' + this.props.input.name);
      console.log(this.props);
    }

    const { onFocus, onBlur, ...myInput } = input;
    const iconcolor = this.props.iconcolor ? this.props.iconcolor : '#CDCDCD';

    let typeInput = otherProps.typeInput ? otherProps.typeInput : 'text';

    let inputFullNameWithLine = this.props.input.name;
    /*
    resolve source , twin SOU554
     */

    let sourceField = {};

    if (otherProps.pathInTables) {
      //console.log('otherProps.pathInTables', otherProps.pathInTables);
      sourceField = resolvePathObj(Tables, otherProps.pathInTables);
      if (typeof sourceField !== 'undefined') {
        // for customize format det dont use date navigator
        if (sourceField.dbtype === 'Date' && !setDateFormat) typeInput = 'date';
      } else {
        sourceField = {}; // so give no errors in using subkeys for this property
      }
    } else if (otherProps.pathInObject) {
      sourceField = otherProps.pathInObject;
    }

    /*
     div Errors needs set fixed accord first to Width (priorirty) or minWith ( less prioritary,
     and have always default), because in resize responsive, without minWidth inputs become very narrow
     and container <div className="formRow"> need a maxWidth  becase text can expand more than minWidth
    */

    const defaultWidth = '300px';
    /*
    styles will be fusioned below on inputProps
     */
    let styleInputWidth = {};
    let styleRowWidth = {};

    // priority props, after source for width // to change container and input
    // the variable styleInputWidth will come styleRowWidth , and will be used later on line:
    // <div key="label"  className={classRow} style={{ position: 'relative', ...styleRowWidth }}>
    if (otherProps.width || sourceField.width) {
      styleInputWidth.width = otherProps.width
        ? otherProps.width
        : sourceField.width
        ? sourceField.width
        : defaultWidth;
    }

    if (sourceField.border === 2) {
      styleInputWidth.borderRight = 'solid 1px #3576B5';
      styleInputWidth.paddingRight = '5px';
    }
    // minWidth just if not inLine, if not radio buttons inline, (in firefox) take too much width
    if (!sourceField.inLine)
      styleInputWidth.minWidth = otherProps.width
        ? otherProps.width
        : styleInputWidth.width;
    styleInputWidth.maxWidth = otherProps.width
      ? otherProps.width
      : styleInputWidth.width;
    //if (sourceField.width) console.log('styleInputWidth',styleInputWidth);

    const styleInput = Object.assign({}, styleInputWidth);
    if (this.props.formState) {
      //twin HID565, why his twin for subfields does not read sourceField.hidden...?
      if (
        (sourceField.hiddenOnAdd &&
          this.props.formState &&
          this.props.formState.actionsave === 'add') ||
        (sourceField.hiddenOnUpdate &&
          this.props.formState &&
          this.props.formState.actionsave === 'update')
      ) {
        typeInput = 'hidden';
      }
    }

    if (typeInput === 'hidden') styleInputWidth = { display: 'none' }; // hidden type no display

    styleRowWidth = styleInputWidth;

    if (otherProps.index) {
      // is FieldArray
      styleInput.fontSize = '14px';
      styleInput.padding = '7px';
    }

    if (touched && error) styleInput.border = 'solid 1px red';
    if (icon) styleInput.paddingLeft = '35px';

    /*
      props general for input ,(useful for onHidden, onDisable, etc)
     */
    const inputProps = {
      type: typeInput, // needed because type is not setted really before
    };

    // && by the moment force, to be expand if input is textarea %% normaly must to check for field.formNewSection
    // like generator check and produce 'formSectionExpand' for formRow container
    let classRow;
    let formRowExpand;
    formRowExpand = otherProps.formRowExpand
      ? otherProps.formRowExpand
      : sourceField.formRowExpand
      ? sourceField.formRowExpand
      : false;
    let formRowInsideCenter;
    formRowInsideCenter = otherProps.formRowInsideCenter
      ? otherProps.formRowInsideCenter
      : sourceField.formRowInsideCenter
      ? sourceField.formRowInsideCenter
      : false;
    let styleInsideRow = { position: 'relative' };

    let isInLine = false;

    /*
    Assign from .props to SourceField,  keys that can come from props, but the code check from sourceField
     */
    if (typeof this.props.placeHolder !== 'undefined')
      sourceField.placeHolder = this.props.placeHolder;
    if (typeof this.props.inLineInputFirst !== 'undefined')
      sourceField.inLineInputFirst = this.props.inLineInputFirst;
    if (typeof this.props.inLine !== 'undefined')
      sourceField.inLine = this.props.inLine;
    if (typeof this.props.onHidden !== 'undefined') sourceField.onHidden = true;

    if (sourceField.inLine || sourceField.inLineInputFirst) {
      /* align at top when there a fields with more height than another on the same row
         but really now field has his own container, so thinks is useful in div container grandparent
      */
      //styleRowWidth.display = 'flex';
      //styleRowWidth.alignItems = 'center';
      isInLine = true;
    }

    if (sourceField.sectionStyle) {
      // %%% is repeated in generator for normal field, but for array field is needed
      styleRowWidth = sourceField.sectionStyle.formRow;
    } else if (typeInput === 'textarea' || formRowExpand) {
      classRow = 'formRowExpand';

      if (formRowInsideCenter) {
      }

      if (typeInput !== 'textarea') {
        styleRowWidth = { width: '100%', margin: 'auto', display: 'block' };
        inputProps.style = { width: 'auto', float: 'left' };
      } else {
        // if (otherProps.width || sourceField.width)
        // for textarea, if width (inputwidth) is sent , then take this width for the container
        // styleRowWidth dont touch
      }
      //styleInput.margin = 'auto';
    } else if (isInLine) {
    } else {
      classRow = 'formRow';
      if (typeInput !== 'multiplecheck') {
        styleRowWidth.marginBottom = 'auto'; // %%% intertan descontinuarlo
      }
    }
    // console.log('input',this.props);

    /*
     props for react suggest ( freeAutocomplete, )
     if not is this input will not load or was time looking for these functions
     */
    const freeAutoCpteInputProps = {
      placeholder: '',
    };

    /*
     Important react-autosuggest component get the properties for <input> only trough
     these properties with <  > inputProps={freeAutoCpteInputProps} </ >
     */
    if (typeInput === 'freeAutocomplete') {
      freeAutoCpteInputProps.value = this.getSuggestValue();
      freeAutoCpteInputProps.onChange = this.onChangeSuggest;
      //freeAutoCpteInputProps.autocomplete = 'new-password';
    }

    /*
      props general that are not included like props on input. eg. label
     */
    let propsOutsideInput = {};

    let possibleLabel;
    if (this.props.labelNoTranslate) {
      possibleLabel = otherProps.label;
    } else {
      // firs step; dont translate to dont get errors when field has no label
      possibleLabel = otherProps.label
        ? otherProps.label
        : sourceField.label
        ? sourceField.label
        : '';
      if (possibleLabel) possibleLabel = tt(t, possibleLabel);
    }

    if (!sourceField.noLabel) {
      propsOutsideInput.label = possibleLabel; // forward set empty anyway
    }

    propsOutsideInput.sublabel = otherProps.sublabel
      ? otherProps.sublabel
      : sourceField.sublabel
      ? sourceField.sublabel
      : '';
    propsOutsideInput.sublabeldown = otherProps.sublabeldown
      ? otherProps.sublabeldown
      : sourceField.sublabeldown
      ? sourceField.sublabeldown
      : '';
    propsOutsideInput.loadAsync = false;

    //console.log('sourceField for;' + inputFullNameWithLine,sourceField);

    // let it before: /if (otherProps.noLabel/ because label have value asigned before // default label for empty id
    if (otherProps.placeHolderShow || sourceField.placeHolderShow) {
      // placeHolder can be found in source, if not it's the label, or it's sent like props
      inputProps.placeholder = sourceField.placeHolder
        ? '...' + tt(t, sourceField.placeHolder)
        : '...' + possibleLabel; // for nolabel input, use this secure label value
    }
    //console.log(inputFullNameWithLine + ' label:'+ propsOutsideInput.label  + ', typeInput='+typeInput+', propsOutsideInput.loadAsync',propsOutsideInput.loadAsync);

    let noLabel = otherProps.noLabel ? otherProps.noLabel : sourceField.noLabel;
    if (noLabel) {
      propsOutsideInput.label = '';
    }
    /*
    assing style to inputProps
     */
    if (typeInput === 'checkbox') {
      inputProps.style = { width: 'auto', float: 'left' };
    } else if (typeInput === 'radio') {
    } else if (typeInput === 'selectAutocomplete') {
      // have no styles here
    } else if (typeInput !== 'textarea') {
      styleInput.minWidth = styleInputWidth.minWidth
        ? styleInputWidth.minWidth
        : '300px';
      inputProps.style = styleInput;
      inputProps.className = typeInput !== 'color' ? 'formInput' : '';
    }

    // fusion styles , storing on inputProps
    if (this.props.styleInput || sourceField.styleInput) {
      inputProps.style = Object.assign(
        inputProps.style || {},
        this.props.styleInput
          ? { ...this.props.styleInput }
          : { ...sourceField.styleInput }
      );
    } else {
      // add overwrite source style
      inputProps.style = Object.assign(inputProps.style || {}, {
        ...sourceField.styleInput,
      });
    }

    // warning for react use 'new-password' for all type of inputs, 'off', 'false', does not work
    inputProps.autoComplete = this.props.autocomplete
      ? this.props.autocomplete
      : typeInput === 'password'
      ? 'new-password'
      : 'new-password';

    // onChange comes already from Form.js made by generator,
    // model for onChange for Autocomplete:
    // onChange={this.onChangeAutocomplete.bind(this, this.props.input.name)}
    if (this.props.mustOnChange && typeInput === 'selectAutocomplete') {
      inputProps.onChange = this.onChangeAutocomplete.bind(
        this,
        this.props.input.name
      );
    }

    let haslistSource = otherProps.listSource
      ? otherProps.listSource
      : sourceField.listSource
      ? sourceField.listSource
      : '';
    let disableTranslate =
      sourceField.translateDisabledSource || this.props.translateDisabledSource;
    let listOptionsCandidate = this.props.listOptions
      ? this.props.listOptions
      : null;

    if (haslistSource) {
      inputProps.options = sourcesOptions[haslistSource].map((list) => ({
        ...list,
        id: list.id,
        name: disableTranslate
          ? list.name
          : tt(t, `${haslistSource}.${list.name}`),
      }));
      // multiplecheck use  .listOptions and not .options (must be unified ? %%%, see asyn autocomplete case)
      if (typeInput === 'multiplecheck')
        listOptionsCandidate = inputProps.options;
    }
    // key must be clean []. // by the moment like this, study why hidden or disable work different
    // fieldarray send full name without special chars: inputFullNameCtrl (isSubfield = true: redundant)
    // if not will use input name (inputFullNameWithLine = normal name input)
    let inputFullNameCtrl =
      this.props.inputFullNameCtrl || inputFullNameWithLine;

    let listOptions = listOptionsCandidate
      ? listOptionsCandidate
      : sourceField.listOptions
      ? sourceField.listOptions
      : [];

    if (
      listOptions &&
      listOptions.length === 0 &&
      this.props &&
      this.props.formState &&
      // this.props.formState.listOptions &&
      // inputFullNameCtrl.includes('transportationseat'

      // now is based on field listOptionsFromState (replace previous 2 lines)
      //    1.  dont change accord to line if it's multiarray
      //              Example: transportation + seat + 1 = transportationseat
      //    2.  change accord to line (use full in multiarray),
      //         if field is normal field chnage has no effect.
      //              Example: transportation + seat + 1 = transportationseat1

      sourceField.listOptionsFromState
    ) {
      // console.log(inputFullNameCtrl, this.props.formState.listOptions[inputFullNameCtrl]);

      let nameVarStateOptions;

      if (sourceField.listOptionsFromState == 2) {
        // include line (multiarray)
        nameVarStateOptions = inputFullNameCtrl;
      } else {
        // when == 1  , dont include line
        nameVarStateOptions =
          this.props.fieldParentKey + this.props.fieldOnlyName;
        if (logOptionsFromState) {
          console.log(
            '*OptionsFromState nameVarStateOptions',
            nameVarStateOptions
          );
          console.log('*OptionsFromState this.props', this.props);
        }
      }
      if (logOptionsFromState) {
        console.log(
          '*OptionsFromState sourceField.listOptionsFromState',

          sourceField.listOptionsFromState,
          'state form',
          this.props.formState
        );
      }

      if (
        resolvePathObj(this.props, 'formState[' + nameVarStateOptions + ']')
      ) {
        listOptions = this.props.formState[nameVarStateOptions];
        if (logOptionsFromState) {
          console.log(
            '*OptionsFromState found state listOptions ',
            listOptions
          );
        }
      }
    }
    // if ( this.props.isSubfield  && this.props.input.name.includes('inputRadios') && listOptions) console.log('listOptions', this.props.input.name, listOptions) ;
    // if (  this.props.input.name.includes('diet') && listOptions) console.log('listOptions', this.props.input.name, listOptions, 'haslistSource', haslistSource) ;

    let valueCurrentInput = '';
    //  %%% this line below is not organized ( listOptions must be charged in same way logic that listOpions)
    //  !(typeInput === "selectAutocomplete" && haslistSource)
    if (
      (sourceField.table ||
        sourceField.ql ||
        (listOptions && listOptions.length > 0)) &&
      !(typeInput === 'selectAutocomplete' && haslistSource)
    ) {
      if (logOptionsFromState && this.props.fieldOnlyName) {
        console.log(
          '*OptionsFromState one',
          this.props.fieldOnlyName,
          listOptions
        );
      }

      // only when has no listOptions ( listOptions variable has sourceField or   props value)
      // hast table, must  to load table
      if (
        typeInput === 'selectBox' &&
        !(listOptions && listOptions.length > 0)
      ) {
        // if props is sent, dont load here options
        let fieldDisplayedOptions = sourceField.fieldDisplayedOptions
          ? sourceField.fieldDisplayedOptions
          : {};
        let filterByObj = {}; // filter not disponible, on the future implement like autocomplete
        let sourceObject;
        if (
          (sourceField.table && !sourceField.conditionalTable) ||
          (sourceField.table &&
            this.props.syncCode &&
            this.props.syncCode(sourceField.conditionalTable[0], {
              formProps: this.props.formProps,
              formState: this.props.formState,
            }) === sourceField.conditionalTable[1])
        ) {
          sourceObject = sourceField.table;
          // suspend async, when be working
        } else if (sourceField.ql) {
          // parameter is sent like object, and it's special ql calls, not related with table
          // by the moment only  for tour.services.tourservices_id { ql: 'getTourServices' } that need load
          // special records
          // in this case in not prepared for loadAsync
          sourceObject = { ql: sourceField.ql };
          if (sourceField.loadAsync) propsOutsideInput.loadAsync = true;
        } else {
          // this.props.listOptions , this case with list options insert manually on coomponent is processed below
        }
        listOptions = getOptions(
          sourceObject,
          this.props.formProps,
          fieldDisplayedOptions,
          filterByObj,
          false,
          sourceField.subfield && !sourceField.subfields
            ? sourceField.subfield
            : null
        );
      }
      if (typeInput === 'selectAutocomplete') {
        // keep condition for .ql || .table
        let fieldDisplayedOptions = sourceField.fieldDisplayedOptions
          ? sourceField.fieldDisplayedOptions
          : {};
        // prepare variables to filter, or show only some imtes accord filter object
        //console.log(inputFullNameWithLine, sourceField);
        let filterBy = [];
        if (
          sourceField.filterBy &&
          typeof sourceField.conditionalfilterBy === 'undefined'
        ) {
          filterBy = sourceField.filterBy;
        } else if (
          sourceField.filterBy &&
          typeof sourceField.conditionalfilterBy !== 'undefined'
        ) {
          // twin FL23, works together with server that read this variables to reply the result
          let filterActivate;
          if (
            sourceField.conditionalfilterBy[0].substr(0, 1) === '*' &&
            this.props.syncCode
          ) {
            filterActivate =
              this.props.syncCode(sourceField.conditionalfilterBy[0], {
                formProps: this.props.formProps,
                formState: this.props.formState,
              }) === sourceField.conditionalfilterBy[1];
          } else if (
            realTypeOf(sourceField.conditionalfilterBy) === '[object String]'
          ) {
            filterActivate = !resolvePathObj(
              this.props,
              sourceField.conditionalfilterBy[0],
              { compare: sourceField.conditionalfilterBy[1], notFound: false }
            );
          }
          if (filterActivate) filterBy = sourceField.filterBy;
        }
        let filterByObj = {};
        if (!propsOutsideInput.loadAsync) {
          filterBy.map((field) => {
            let realfield = '';
            let fieldValue;
            if (this.props.isSubfield && sourceField.subfield) {
              // very tricky, unique case, customer categorie, has subcateogires not in another table, but in a subfield json
              // need manually retrive the value of the categorie in this way, why the others with subfield in table like transport and stoppoint not
              // getInputValue, guess the name of parent in a wrong name, not able to guess the names of parents   field[line]subfield
              // see on the case grandchilds...., try to send to getInputValue , parameters with the parent, and grandparent
              // o to improve the way guess those variables
              let inputNameArray = this.props.input.name.match(
                /(\w+)\[([\d*]+)\]\.(\w+)/
              );
              fieldValue = getInputValue(
                this.props.formProps,
                inputNameArray[1],
                { line: inputNameArray[2], subfield: field }
              );
              //getInputValue(this.props.formProps, realfield, {}, true);
              if (!fieldValue) {
                fieldValue = null;
              }
            } else if (field.includes('*')) {
              // filter is located in subfields, resolve item
              const inputNameArray = this.props.input.name.match(
                /(\w+)\[([\d*]+)\]\.(\w+)/
              );
              //console.error('inputNameArray',inputNameArray);
              realfield = field.replace('*', inputNameArray[2]);
              fieldValue = getInputValue(this.props.formProps, realfield);
            } else {
              fieldValue = getInputValue(this.props.formProps, field);
            }

            // console.log('field name, value', field,realfield,fieldValue );
            //console.log('getInputValue for field:'+field,fieldValue);
            filterByObj[field] =
              typeof fieldValue === 'undefined' ? '' : fieldValue;
          });
        }
        //console.log('inputName: '+this.props.inputName+', filterByObj :',filterByObj );
        let sourceObject;
        if (listOptions && listOptions.length > 0) {
          // this case with list options now has proprity; avoiden rest blocks
          // insert manually on coomponent is processed below
          if (logOptionsFromState && this.props.fieldOnlyName) {
            console.log(
              '*OptionsFromState two',
              this.props.fieldOnlyName,
              listOptions
            );
          }
        } else if (
          (sourceField.table && !sourceField.conditionalTable) ||
          (sourceField.table &&
            this.props.syncCode &&
            this.props.syncCode(sourceField.conditionalTable[0], {
              formProps: this.props.formProps,
              formState: this.props.formState,
            }) === sourceField.conditionalTable[1])
        ) {
          sourceObject = sourceField.table;
          // suspend async, when be working
          if (Tables[sourceObject].loadAsync)
            propsOutsideInput.loadAsync = Tables[sourceObject].loadAsync;
        } else if (sourceField.ql) {
          // parameter is sent like object, and it's special ql calls, not related with table
          // by the moment only  for tour.services.tourservices_id { ql: 'getTourServices' } that need load
          // special records
          // in this case in not prepared for loadAsync
          sourceObject = { ql: sourceField.ql };
          if (sourceField.loadAsync) propsOutsideInput.loadAsync = true;
        } else {
          // this.props.listOptions , this case with list options insert manually on coomponent is processed below
        }
        valueCurrentInput = getInputValue(this.props, this.props.inputName);

        //console.log('this.props.inputName:'+this.props.inputName,valueCurrentInput);
        // Here resolve the real options for not sync
        if (logOptionsFromState && this.props.fieldOnlyName) {
          console.log(
            '*OptionsFromState three',
            this.props.fieldOnlyName,
            propsOutsideInput.loadAsync,
            listOptions
          );
        }
        if (!propsOutsideInput.loadAsync) {
          if (listOptions && listOptions.length > 0) {
            if (logOptionsFromState && this.props.fieldOnlyName) {
              console.log(
                '*OptionsFromState four listOptions',
                this.props.fieldOnlyName,
                listOptions
              );
            }

            inputProps.options = listOptions;
          } else {
            //if (inputFullNameWithLine.includes('services')) {
            //  inputProps.options = getOptions(sourceObject,this.props.formProps, fieldDisplayedOptions, filterByObj, false, sourceField.subfield && !sourceField.subfields ? sourceField.subfield: null, false );

            // } else {
            inputProps.options = getOptions(
              sourceObject,
              this.props.formProps,
              fieldDisplayedOptions,
              filterByObj,
              false,
              sourceField.subfield && !sourceField.subfields
                ? sourceField.subfield
                : null
            );
            //}
          }

          /*let options = getOptions(sourceObject,this.props.formProps, fieldDisplayedOptions, filterByObj);
          if (valueCurrentInput) {
            console.log('add value;',valueCurrentInput);
            console.log(options);
            options = [...options ,  valueCurrentInput ];
          }
          inputProps.options  = options;*/
        } else {
          // Important send the 'valueCurrentInput' so the list can load at leas the previous value,
          // if not will show in blank
          // action 'add', 'update' is not actually used in debounce, is for the future
          let action =
            this.props.formState && this.props.formState.actionsave
              ? this.props.formState.actionsave
              : '';
          //console.log('action',action);
          /*inputProps.loadOptions = this.loadSyncOptions (sourceField.ql ? sourceField.ql : sourceField.table,
            this.props.inputName, valueCurrentInput, { action, fieldDisplayedOptions, sourceField });*/
          inputProps.loadOptions = this.debouncedloadSyncOptions(
            sourceField.ql ? sourceField.ql : sourceField.table,
            this.props.inputName,
            valueCurrentInput,
            { action, fieldDisplayedOptions, sourceField }
          );
        }
        //inputProps.options = this.state.options;
        //console.log('inputName: '+this.props.inputName+', options:',inputProps.options);
      }
    }

    /*
    Warning only for autocomplete, for another inputs is resolved before
     */
    // after resolver if it's loadAsync, modify placeHolder for autocomplete
    if (propsOutsideInput.label) {
      if (typeInput === 'selectAutocomplete') {
        if (propsOutsideInput.loadAsync) {
          if (sourceField.add) {
            inputProps.placeholder = tt(t, 'info.searchPromptAdd');
          } else {
            inputProps.placeholder = tt(t, 'info.searchPrompt');
          }
        } else {
          inputProps.placeholder = tt(t, 'info.selectPlaceHolder');
        }
      }
    }

    /*
      // below coments not more, hidden and disabled is resolved here accord to .sourceField
      //otherProps.disabled and otherProps.hidden
      //are properties  inherited from Form or FormArayField.js, resolved accord
      //onDisable (if that have) or direct definition 'disabled'
     */
    let tableCrud = this.props.pathInTables
      ? this.props.pathInTables.split('.')[0]
      : '';
    if (tableCrud && Tables[tableCrud].readOnly) {
      inputProps.disabled = true;
    }
    if (
      sourceField.disabledOnAdd &&
      this.props.formState &&
      this.props.formState.actionsave === 'add'
    ) {
      inputProps.disabled = true;
    }
    if (
      sourceField.disabledOnUpdate &&
      this.props.formState &&
      this.props.formState.actionsave === 'update'
    ) {
      inputProps.disabled = true;
    }
    if (sourceField.disabled || otherProps.disabled) {
      // this case, consider sourceField.disabled if otherProps is not disable
      // is for the fields are disable any situation
      let disabled = otherProps.disabled
        ? otherProps.disabled
        : sourceField.disabled;
      if (disabled) {
        freeAutoCpteInputProps.disabled = otherProps.disabled; // for select Suggest , that use this for propagate props
        inputProps.disabled = disabled; // para controls <> suggest
      }
    }
    if (sourceField.disabledWhen) {
      // twin OP920
      for (const [fieldKey, fieldCond] of Object.entries(
        sourceField.disabledWhen
      )) {
        const fieldProps = fieldKey.split('_');
        let fieldName;
        let fieldValue;
        if (fieldProps[0] === 'init') {
          fieldName = fieldProps[1];
          fieldValue = getInputValue(this.props.formProps, fieldName, {
            initial: true,
          });
        } else {
          fieldName = fieldProps[0];
          fieldValue = getInputValue(this.props.formProps, fieldName);
        }
        for (const [key, value] of Object.entries(fieldCond)) {
          if (key === '$equal') {
            if (fieldValue === value) inputProps.disabled = true;
          }
          if (key === '$notequal') {
            if (fieldValue !== value) inputProps.disabled = true;
          }
        }
      }
    }
    if (otherProps.hidden) {
      inputProps.type = 'hidden';
    } else if (!this.props.isSubfield && sourceField.onHidden) {
      // not for ArrayField, because was made on already on ArrayField
      // if is before hidden, not needed to be enter here
      //TWIN ONH021 -
      /*console.log('inputname',this.props.input.name);
      console.log('is hidden ? this.props.formState',this.props.formState);
      console.log('this.props',this.props);*/
      let isHidden =
        this.props.formState.hiddenFields &&
        this.props.formState.hiddenFields[this.props.input.name];
      if (isHidden) {
        inputProps.type = 'hidden';
      }
      // change state to get re-render
    }

    // important por block below that print input
    if (inputProps.type === 'hidden') typeInput = 'hidden';

    /* delete id for inputs for possible conflicts with values for inputs
    id={this.lastUniqueId()}
    htmlFor={this.nextUniqueId()}
    */

    let view = false;
    if (sourceField.view || this.props.view) view = true; // display text , not input
    //typeInput: 'urlLink'

    let dataInputs = [];
    if (icon) {
      dataInputs.push(
        <div key="icon" className="iconInput">
          <ShowIcon
            icon={icon}
            color={iconcolor}
            animatePath={
              this.props.animatePath && this.state.isFocus ? '*' : null
            }
          />
        </div>
      );
    }
    let valueInput;
    //console.log('**fieldname',this.props.input.name);
    let fieldValue = this.props.formProps
      ? getInputValue(this.props.formProps, this.props.input.name)
      : undefined;
    const aOptions = []; // for option groups or for content input with flex method display
    //console.log('valueInput',valueInput,this.props);
    // got craizy: if ((typeof valueInput === 'undefined'  )&& this.props.defaultValue ) valueInput = (this.props.isFilter ? this.props.defaultValue : '');
    // console.log('valueInput final',valueInput);
    let labelInput;
    let inputElements = [];
    if (view) {
      // view display a hidden input, but in render just retunr de input hidden, so add label
      if (typeInput === 'checkbox') {
        let fieldValueShow = fieldValue ? tt(t, 'form.yes') : tt(t, 'form.no');
        dataInputs.push(fieldValueShow);
      } else {
        dataInputs.push(fieldValue);
      }
      inputProps.type = 'hidden'; // logic need inputs be present, to get value to set like  disabled or hidden the inputs
      dataInputs.push(
        <input key={inputFullNameWithLine} {...myInput} {...inputProps} />
      );
      let PreComponent;

      if (
        this.props.preComponent &&
        this.props.preComponentSet.position === 'replaceInput'
      ) {
        // this reemplace all the label and input for the components

        PreComponent = this.props.preComponent;
        inputElements.push(<PreComponent key="prec" {...this.props} t={t} />);

        propsOutsideInput.label = ''; // remove possible label
      }
    } else if (typeInput === 'selectBox') {
      // value = {valueInput ? valueInput : ""}
      let fieldValue = getInputValue(
        this.props.formProps,
        this.props.input.name
      );
      //console.log('inputFullNameWithLine for:'+ this.props.input.name,fieldValue );
      //console.log('this.props.formProps',this.props.formProps);
      let listProccesed = [];

      if (listOptions && listOptions.length > 0) {
        listOptions.map((list) => {
          let prefixLabel = !this.props.noPrefixOptionLabel ? '...' : '';
          const name =
            (list.id ? '' : prefixLabel) +
            (!disableTranslate ? tt(t, `${list.name}`) : list.name);
          listProccesed.push({
            id: list.id,
            name,
            disabled: list.disabled ? true : false,
          });
          //<option key={list.id} value={list.id}>{ !disableTranslate ? tt(t,`${list.name}`) : list.name}</option>
        });
      }
      if (this.props.listSource) {
        sourcesOptions[this.props.listSource].map((list) => {
          //<option key={list.id} value={list.id}>{ !disableTranslate ? tt(t,`${this.props.listSource}.${list.name}`) : list.name}</option>
          listProccesed.push({
            id: list.id,
            name: !disableTranslate
              ? tt(t, `${this.props.listSource}.${list.name}`)
              : list.name,
          });
        });
      }
      if (this.props.reduxSource) {
        /* NO IN USE, future %%*/
        sourcesOptions[this.props.listSource].map((list) => {
          //<option key={list.id} value={list.id}>{ !disableTranslate ? tt(t,`${this.props.listSource}.${list.name}`) : list.name}</option>
          listProccesed.push({
            id: list.id,
            name: !disableTranslate
              ? tt(t, `${this.props.listSource}.${list.name}`)
              : list.name,
          });
        });
      }

      // redux form; same if this first empty item is not added, first item is not autoselect, and if there is just one item
      // redux form does not detecte change value, so it's better use always de default value method for redux form
      if (listProccesed && listProccesed[0] && listProccesed[0].id !== '') {
        listProccesed = [
          {
            id: '',
            name: `${
              inputProps.placeholder
                ? inputProps.placeholder
                : tt(t, 'form.selectInputLabel')
            }`,
          },
          ...listProccesed,
        ];
      }
      /*if (tableCrud && Tables[tableCrud].readOnly) {
        dataInputs.push(<div>{fieldValue}</div>) ;
      } else {*/
      dataInputs.push(
        <select
          key={inputFullNameWithLine}
          {...input}
          style={styleInput}
          {...myInput}
          {...inputProps}
          autoComplete="new-password"
          className="formSelectBox"
        >
          {listProccesed.map((list) => (
            <option
              key={list.id}
              value={list.id}
              disabled={list.disabled ? 'disabled' : null}
            >
              {list.name}
            </option>
          ))}
        </select>
      );

      /*}*/
    } else if (typeInput === 'xxfreeAutocomplete') {
      dataInputs.push(
        <Creatable
          key={inputFullNameWithLine}
          options={this.props.options}
          {...myInput}
          promptTextCreator={(label) => `(* Nouveau) ${label} `}
          newOptionCreator={(label) => {
            //console.log('newOptionCreator', label);
            return { label: 'mylabel', labelKey: '000', valueKey: 'dd' };
          }}
          onBlur={() => this.props.input.onBlur(this.props.input.value)} // id is name of propery of record
          multi={false}
          nameForm={this.props.nameForm ? this.props.nameForm : ''}
          value={getInputValue(this.props)}
        />
      );
    } else if (
      typeInput === 'selectAutocomplete' &&
      propsOutsideInput.loadAsync
    ) {
      let asyncValue = this.getAsyncInputValue(
        sourceField.table,
        this.props.inputName,
        valueCurrentInput
      );

      //console.log('loadAsync', this.props.inputName, asyncValue, realTypeOf(asyncValue) );
      if (
        asyncValue &&
        realTypeOf(asyncValue) === '[object Object]' &&
        (!asyncValue.id || realTypeOf(asyncValue.id) === '[object Object]')
      ) {
        //console.log('nullllllllllll');
        asyncValue = null; // prevent wrong value for type object, if not {id: okvalue} or {id: {id:'',name}} then null
      }
      /*if (tableCrud && Tables[tableCrud].readOnly) {
        dataInputs.push(<div>ver</div>);

      } else {*/
      dataInputs.push(
        <Async
          key={inputFullNameWithLine}
          valueKey="id"
          labelKey="name"
          ignoreAccents={false}
          {...myInput}
          {...inputProps}
          searchPromptText={tt(t, 'info.searchPlaceHolder')}
          cache={false}
          onBlur={() => this.props.input.onBlur(this.props.input.value)} // id is name of propery of record
          multi={false}
          nameForm={this.props.nameForm ? this.props.nameForm : ''}
          value={asyncValue}
        />
      );

      /*}*/
    } else if (
      typeInput === 'selectAutocomplete' &&
      propsOutsideInput.loadAsync &&
      otherProps.xxdisabled
    ) {
      dataInputs.push(
        <span key="input">
          {this.getAsyncInputValue(
            sourceField.table,
            this.props.inputName,
            valueCurrentInput
          )}
        </span>
      );
    } else if (
      typeInput === 'selectAutocomplete' &&
      !propsOutsideInput.loadAsync
    ) {
      // only if table globally is readonly, %%% to do not only for the global table, but just case for the field
      if (
        tableCrud &&
        (Tables[tableCrud].readOnly ||
          (sourceField.readOnlyUpdate &&
            this.props.formState &&
            this.props.formState.actionsave === 'update')) &&
        sourceField &&
        (sourceField.table || sourceField.ql)
      ) {
        const nameValueRelated = resolvePathObj(
          this.props,
          'formProps.data.' + tableCrud + this.props.inputName + '.name'
        );
        if (nameValueRelated) {
          // get Value from ql loaded
          dataInputs.push(
            <div key={tableCrud + this.props.inputName} style={styleReadOnly}>
              <div>{nameValueRelated}</div>
            </div>
          );
        } else if (this.props.input.value && this.props.input.value.name) {
          // get Value direct from { id, name}
          dataInputs.push(
            <div key={tableCrud + this.props.inputName} style={styleReadOnly}>
              <div>{this.props.input.value.name}</div>
            </div>
          );
        }
      } else {
        if (sourceField.version === 'Next') {
          dataInputs.push(
            <SelectNext
              key={inputFullNameWithLine}
              valueKey="id"
              labelKey="name"
              {...myInput}
              {...inputProps}
              isMulti
              nameForm={this.props.nameForm ? this.props.nameForm : ''}
              value={getInputValue(this.props)}
            />
          );
        } else {
          dataInputs.push(
            <Select
              key={inputFullNameWithLine}
              valueKey="id"
              labelKey="name"
              {...myInput}
              {...inputProps}
              onBlur={() => this.props.input.onBlur(this.props.input.value)} // id is name of propery of record
              multi={false}
              nameForm={this.props.nameForm ? this.props.nameForm : ''}
              value={getInputValue(this.props)}
            />
          );
        }
      }
    } else if (typeInput === 'textarea') {
      if (inputProps.disabled) {
        dataInputs.push(<div key={inputFullNameWithLine}>{fieldValue}</div>);
      } else {
        dataInputs.push(
          <textarea
            key={inputFullNameWithLine}
            onFocus={(e) => {
              onFocus(e);
              this.setFocus(true);
            }}
            onBlur={(e) => {
              onBlur(e);
              this.setFocus(false);
            }}
            className="formTextarea"
            {...myInput}
            {...inputProps}
            autoFocus={otherProps.autoFocus ? 'true' : ''}
            type={typeInput}
          />
        );
      }
    } else if (typeInput === 'mask') {
      dataInputs.push(
        <MaskedInput
          key={inputFullNameWithLine}
          onFocus={(e) => {
            onFocus(e);
            this.setFocus(true);
          }}
          onBlur={(e) => {
            onBlur(e);
            this.setFocus(false);
          }}
          mask={[
            '(',
            /[1-9]/,
            /\d/,
            /\d/,
            ')',
            ' ',
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          className={typeInput !== 'color' ? 'formInput' : ''}
          guide={false}
          {...myInput}
          {...inputProps}
        />
      );
    } else if (typeInput === 'urlLink') {
      let linkFile =
        window?.appConfig?.REACT_APP_DOWN_URI +
        this.getAsyncInputValue(
          sourceField.table,
          this.props.inputName,
          valueCurrentInput
        );
      if (linkFile) {
        // only show if there is value
        dataInputs.push(
          <a key="urlLink" href={linkFile}>
            <ShowIcon icon="download" color="#62a7d0" />
          </a>
        );
      }
    } else {
      let lnI = 1;
      let radioValues = [];
      let typeValueRadio = '';
      //let thereisRangeDate = false;
      if (otherProps.options) {
        // options are defined in a array of objects
        //typeValueRadio = sourceField.type;
        /*otherProps.options.map ( (option) => {
          radioValues.push( { value: option.id, label: tt(t,option.name) });
          //if (option.name === 'form.rangedate') thereisRangeDate = true;
        });*/
        /*} else {
        // the Boolean value need to be fake like string, so vaulues like null and false can be differentiate
        typeValueRadio = 'Boolean';
        radioValues = [
          { value: 'false', label: tt(t,'form.no')},
          { value: 'true', label: tt(t,'form.yes')},
        ];
        // for filter use 3 options, use 3 options just case filter, and radio for boolean, for more 2 values
        // don't use radio, use select, so here radio = 3 is filter
        if (otherProps.radio === 3) {
          // value cant' be blank '', because after clicks in anoter filss got no click in this option
          radioValues.push({ value: 'null', label: tt(t,'form.all') });
         }*/
      }

      while (true) {
        let labelRadio;
        let valueRadio;
        if (typeInput === 'radio' || typeInput === 'multiplecheck') {
          const labelSource =
            listOptions[lnI - 1] && listOptions[lnI - 1].name
              ? listOptions[lnI - 1].name
              : '';
          const year = parseInt(new Date().getFullYear());

          if (labelSource === '{year}') {
            labelRadio = year;
            valueRadio = year;
          } else if (labelSource === '{year+1}') {
            labelRadio = year + 1;
            valueRadio = year + 1;
          } else if (labelSource === '{year-1}') {
            labelRadio = year - 1;
            valueRadio = year - 1;
          } else {
            labelRadio = labelSource ? tt(t, labelSource) : '';
            valueRadio = listOptions[lnI - 1] ? listOptions[lnI - 1].id : '';
          }
        }

        if (typeInput === 'radio') {
          // build radio option one by one for the input
          //need convert to string, because we need to chose a standard type for compare / display etc; string is more convenient
          inputProps.value = valueRadio == null ? '' : valueRadio.toString();
          inputProps.checked =
            (fieldValue == null ? '' : fieldValue.toString()) ===
            inputProps.value;
        }
        //            autoFocus={otherProps.autoFocus ? 'true' : ''}

        const elInput = (
          <input
            key={inputFullNameWithLine}
            onFocus={(e) => {
              onFocus(e);
              this.setFocus(true);
            }}
            onBlur={(e) => {
              onBlur(e);
              this.setFocus(false);
            }}
            {...myInput}
            {...inputProps}
          />
        );
        let itemShow = sourceField.itemShow || this.props.itemShow; // special for forms, not filters

        if (typeInput === 'radio') {
          // neet to change de default label style for the options radio
          const lineRadio = (
            <label
              style={
                Object.keys(inputProps.style).length > 0
                  ? inputProps.style
                  : {
                      color: '#777777',
                      marginRight: '1px',
                      fontSize: itemShow ? '14px' : '13px',
                      letterSpacing: '1px',
                    }
              }
              key={'rad' + lnI}
            >
              {elInput} {labelRadio}
            </label>
          );
          if (itemShow) {
            aOptions.push(lineRadio); // special to print with flex method at the end
          } else {
            dataInputs.push(lineRadio);
          }
        } else if (typeInput === 'multiplecheck') {
          // neet to change de default label style for the options radio
          const lineRadio = (
            <React.Fragment key={'rad' + lnI}>
              {' '}
              <label
                style={{
                  color: '#666666',
                  marginRight: '1px',
                  fontSize: '14px',
                  letterSpacing: '1px',
                }}
              >
                <input
                  type="checkbox"
                  value={this.props.input.value}
                  checked={input.value.indexOf(valueRadio) !== -1}
                  syle={{ padding: '2px', align: 'left' }}
                  onChange={(event) => {
                    const newValue = [...input.value];
                    if (event.target.checked) {
                      newValue.push(valueRadio);
                    } else {
                      newValue.splice(newValue.indexOf(valueRadio), 1);
                    }

                    return input.onChange(newValue);
                  }}
                />
                {labelRadio}
              </label>{' '}
            </React.Fragment>
          );
          if (itemShow) {
            aOptions.push(lineRadio); // special to print with flex method at the end
          } else {
            dataInputs.push(lineRadio);
          }
        } else {
          dataInputs.push(elInput);
        }
        lnI++;
        if (
          (typeInput !== 'radio' && typeInput !== 'multiplecheck') ||
          lnI > listOptions.length
        )
          break;
      }
      /*if (thereisRangeDate) {
        dataInputs.push(<input type='date' className = 'formInput' style={{ fontSize: '13px', width: '150px' }} />);
        dataInputs.push(<input type='date' className = 'formInput' style={{ fontSize: '13px', width: '150px' }} />);
      }*/
    }

    let formLabel = { display: 'inline' }; // now label is always inline, so let another elements be same line
    if (this.props.styleLabel)
      formLabel = Object.assign(formLabel, { ...this.props.styleLabel });
    //let formLabel = { display: 'inline-block' };
    //if (sourceField.inLine) formLabel.display = 'inline';
    if (inputProps.type === 'hidden' && !view) {
      // view is not really hidden, because show label, etc
      return <div>{dataInputs}</div>;
    }

    // heightGroupInput simulate   total height (label, input including padding and margins)
    // to be able to align inline elements accord that height on the middle from another input textbox besides
    const heightGroupInput = '34px';

    /*
    usefull for filter that send this properties like props, and they are not in sourceField, Eg: rangedate
    */

    if (typeof this.props.noContainerInLine !== 'undefined')
      sourceField.noContainerInLine = this.props.noContainerInLine;
    if (
      typeof this.props.noMainContainer !== 'undefined' ||
      sourceField.noMainContainer
    ) {
      sourceField.noMainContainer =
        this.props.noMainContainer || sourceField.noMainContainer;
    }
    if (isInLine) {
      let labelInput;
      if (sourceField.inLineInputFirst) {
        labelInput = (
          <label key="label" className="formLabel" style={formLabel}>
            {dataInputs}
            {propsOutsideInput.label}
          </label>
        );
      } else if (this.props.labelInDiv) {
        labelInput = (
          <label key="label" className="formLabel" style={formLabel}>
            <div>{propsOutsideInput.label}</div> {dataInputs}
          </label>
        );
      } else if (sourceField.inLine && !noLabel) {
        labelInput = (
          <label key="label" className="formLabel" style={formLabel}>
            {propsOutsideInput.label} {dataInputs}
          </label>
        );
      } else if (sourceField.inLine && noLabel) {
        labelInput = (
          <React.Fragment key="nolabel1">{dataInputs}</React.Fragment>
        );
      } else {
        labelInput = (
          <div key="datainput" style={{ marginRight: '9px' }}>
            {dataInputs}
          </div>
        );
      }
      if (sourceField.noContainerInLine || sourceField.noMainContainer) {
        // when elements is alone, no inputs same line, then don't need container with heigth
        inputElements.push(labelInput);
      } else {
        // ,  // discontinued
        /*
        styleRowWidth.marginTop= '9px'; //for radio inline?
        */
        inputElements.push(
          <div
            key="label"
            style={{
              height: heightGroupInput,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {labelInput}
          </div>
        );
      }
    } else {
      let fieldValidatorRequired = false;
      if (this.props.disableValidatorRequired) {
        // no validation, but the problem,    preSubmitValidation() is still  validation on submit
        // and warningFields state is changing accord 'required' validation
        // so , avoit preSubmitValidation() manually in containerform  or dont related field with tables.fields...
        // and validate() in Form
        // and comment blocks on container form:
        // handleSubmit = async (values) => {
        //    /* if (this.state.warningFields && this.state.warningFields._submit && this.state.warningFields._submit.length) {
        // and:
        //   handleSubmitOk = async (values) => {
        //                                                           set four parameter false (validation)
        // const res = await submitValues (tableCrud, this.state.actionsave, this.props, false, this.syncCode);
      } else if (
        this.props.validatorRequired ||
        (sourceField.validators && sourceField.validators.includes('required'))
      ) {
        // BLOCK VALD891 ATTACHED TO BLOCK FF9289
        if (typeof sourceField.conditionalRequired === 'undefined') {
          // this.props.validatorRequired , special for manual cases, when there is not source, but component have the propery written by hand
          fieldValidatorRequired = true;
        } else if (typeof sourceField.conditionalRequired !== 'undefined') {
          if (
            sourceField.conditionalRequired[0].substr(0, 1) === '*' &&
            this.props.syncCode
          ) {
            fieldValidatorRequired =
              this.props.syncCode(sourceField.conditionalRequired[0], {
                formProps: this.props.formProps,
                formState: this.props.formState,
              }) === sourceField.conditionalRequired[1];
          } else {
            fieldValidatorRequired = resolvePathObj(
              this.props,
              sourceField.conditionalRequired[0],
              { compare: sourceField.conditionalRequired[1], notFound: false }
            );
          }
        }
      } else if (
        this.props.formProps &&
        this.props.formProps.containerPropsForm &&
        this.props.formProps.containerPropsForm.requiredFields &&
        this.props.formProps.containerPropsForm.requiredFields[
          this.props.input.name
        ]
      ) {
        // %%% to do not prepared for subfields, just fields, for subfields take in count the full name field,( with container name)
        // console.log('inputFullNameWithLine name', inputFullNameWithLine,this.props.input.name)
        fieldValidatorRequired = true;
      }
      if (propsOutsideInput.label) {
        inputElements.push(
          <label key="label" className="formLabel" style={formLabel}>
            {propsOutsideInput.label}
            {fieldValidatorRequired &&
              !inputProps.disabled &&
              !(
                sourceField.readOnlyUpdate &&
                this.props.formState &&
                this.props.formState.actionsave === 'update'
              ) && <span style={{ color: 'red' }}>*</span>}
            {sourceField.add && !inputProps.disabled && (
              <a
                target="_blank"
                title={
                  tt(t, 'info.vAdd') + ' ' + tt(t, 'table.' + sourceField.table)
                }
                href={
                  '/' +
                  sourceField.table +
                  '-add?popup=' +
                  inputFullNameWithLine
                }
              >
                <ShowIcon
                  height="14"
                  width="14"
                  viewBox="1 1 21 21"
                  color="black"
                  icon="addcircleoutline"
                />
              </a>
            )}
          </label>
        );
      }
      if (propsOutsideInput.sublabel && !propsOutsideInput.sublabeldown) {
        inputElements.push(
          <div key="sublabel" className="formSubLabel">
            {tt(t, propsOutsideInput.sublabel)}
          </div>
        );
      }

      let elementError = (
        <div
          key="msgerror"
          className={!this.props.isFilter ? 'formError' : ''}
          style={
            otherProps.index
              ? { minHeight: '1px', color: '#be0000' }
              : { color: '#be0000' }
          }
        >
          {/* touched &&
            (error &&*/}
          {error &&
            !(
              otherProps.showError &&
              otherProps.showError === 'submitFailed' &&
              !submitFailed
            ) && <span>{errorTranslate(error, t)}</span>}
        </div>
      );

      let warningsFieldDown;
      let warningsFieldUp;
      if (
        resolvePathObj(
          this.props,
          'formState.warningFields[' + inputFullNameCtrl + ']'
        )
      ) {
        warningsFieldDown =
          this.props.formState.warningFields[inputFullNameCtrl];
        if (sourceField.warningUp) {
          warningsFieldUp = warningsFieldDown; // message go down
          warningsFieldDown = null; //
        }
      }
      if (warningsFieldUp) {
        inputElements.push(
          <div
            key="msgwarning"
            style={{
              color: '#555555',
              fontSize: '14px',
              marginTop: '5px',
              marginLeft: '5px',
            }}
          >
            {warningsFieldUp}
          </div>
        );
      }
      if (!otherProps.noErrors && sourceField.errorUp) {
        inputElements.push(elementError);
      }
      if (
        this.props.preComponent &&
        !this.props.preComponentSet.position.includes('outside') &&
        !this.props.preComponentSet.position === 'insideBottom'
      ) {
        // par default witht position, all is inside
        const PreComponent = this.props.preComponent;
        inputElements.push(<PreComponent key="prec" {...this.props} t={t} />);
      }
      if (
        this.props.formState &&
        this.props.formState.actionsave === 'update' &&
        sourceField.recView &&
        sourceField.table &&
        valueCurrentInput &&
        valueCurrentInput.id
      ) {
        classRow = 'smallSelect';
        const url = `/${sourceField.table}-view/${valueCurrentInput.id}`;

        dataInputs.push(
          <div key="rig" style={{ width: '30px', paddingLeft: '2px' }}>
            <a
              onClick={() => this.props.formProps.containerPropsForm.goUrl(url)}
              key="vv"
            >
              <ShowIcon size="18" color="gray" icon="eye" />
            </a>
          </div>
        );
      }

      inputElements.push(
        <div key="inputcnt" style={styleInsideRow}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {dataInputs}
          </div>
          {propsOutsideInput.sublabel && propsOutsideInput.sublabeldown && (
            <div
              key="sublabel2"
              className="formSubLabel"
              dangerouslySetInnerHTML={{
                __html: tt(t, propsOutsideInput.sublabel),
              }}
            ></div>
          )}
        </div>
      );
      //if (this.props.formState && this.props.formState.warningFields && this.props.formState.warningFields[inputFullNameCtrl]
      //  && this.props.formState.warningFields[inputFullNameCtrl].length
      //) {
      if (warningsFieldDown) {
        inputElements.push(
          <div
            key="msgwarning"
            style={{
              color: '#555555',
              fontSize: '14px',
              marginTop: '5px',
              marginLeft: '5px',
            }}
          >
            {warningsFieldDown}
          </div>
        );
      }
      if (
        this.props.preComponent &&
        this.props.preComponentSet.position === 'insideBottom'
      ) {
        let showC = true;
        if (this.props.preComponentSet.condition) {
          showC = resolvePathObj(
            this.props,
            this.props.preComponentSet.condition.compareTo,
            {
              compare: this.props.preComponentSet.condition.compareValue,
              notFound: false,
            }
          );
        }
        if (showC) {
          const PreComponent = this.props.preComponent;
          inputElements.push(<PreComponent key="prec" {...this.props} t={t} />);
        }
      }
      if (!otherProps.noErrors && !sourceField.errorUp) {
        inputElements.push(elementError);
      }
    }
    if (this.props.noMainContainer || typeInput === 'hidden') {
      return <React.Fragment>{inputElements}</React.Fragment>;
    } else {
      if (this.props.isFilter) {
        styleRowWidth.marginTop = '17px';
        if (typeInput === 'radio') {
          styleRowWidth.paddingLeft = '10px';
          styleRowWidth.borderLeft = 'solid 1px #999999';
          styleRowWidth.borderRight = 'solid 1px #999999';
        }
      }

      const inputWithSubContainer = (
        <div
          key="inp"
          className={classRow}
          style={{ position: 'relative', ...styleRowWidth }}
        >
          {inputElements}
        </div>
      );

      if (typeInput === 'textarea') {
        // subfield, need to stay in sameline, if subfiled is not in another container div, but in same container another fields
        // so in this case avoid the padding
        return (
          <div
            className="formSectionExpand"
            style={
              this.props.isSubfield
                ? { padding: '0px', borderBottom: '1px' }
                : {}
            }
          >
            {inputWithSubContainer}
          </div>
        );
      } else if (sourceField.itemShow || this.props.itemShow) {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div key="inp" className={classRow} style={{ ...styleRowWidth }}>
              {inputElements}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {aOptions}
            </div>
          </div>
        );
      } else {
        let PreComponent;
        if (this.props.preComponent) PreComponent = this.props.preComponent;
        return (
          <React.Fragment>
            {inputWithSubContainer}
            {this.props.preComponent &&
              this.props.preComponentSet.position === 'outsideAfter' && (
                <div key="inp" className={classRow}>
                  <PreComponent key="prec" {...this.props} t={t} />
                </div>
              )}
          </React.Fragment>
        );
      }
    }
  }
}

export default translate('translations')(FormField); // give 't' = translator environment and variable
